import { DataSource } from '@n7-frontend/core';
import { IInnerTitleData } from '@n7-frontend/components';
export class TitleDS extends DataSource {

  private lastPayload;
  private showPdfButton = false;

  protected transform(payload, option): IInnerTitleData {
    if (!payload) {
      return;
    }
    this.lastPayload = payload;

    const title = payload.data.general_data[0].value;
    const logo = payload.data.company_logo;
    const actions = this.getActions(payload, option, title);

    return {
      image: logo,
      title: {
        main: {
          text: title,
        }
      },
      tools: '',
      actions: actions
    };
  }

  public waiting(isWaiting) {
    this.update(this.lastPayload, { waiting: isWaiting });
  }

  private getActions(payload, option, title) {
    if (!payload.communication || !payload.communication.config  || !payload.communication.config.get('pdf')) {
      return null;
    }

    const pdfConfig = payload.communication.config.get('pdf');
    const actions = {
      buttons: [
        {
          text: option.waiting ? 'Generazione PDF in corso...' : 'Stampa Dossier Commerciale',
          payload: option.waiting ? null : { type: 'print', id: payload.companyId, name: title, communication: payload.communication, auth: payload.auth, mediator: payload.mediator },
          classes: 'n7-btn-print ' + (option.waiting ? 'n7-btn-print_waiting' : ''),
          icon: 'n7-icon-file-pdf'
        }
      ]
    }
    return pdfConfig.enable_button ? actions : null;
  }
}

import { EventHandler } from '@n7-frontend/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Utils } from '../data-sources/utils';
import { Observable } from 'rxjs';
export class TitleEH extends EventHandler {

  private mediatorMessages$;
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      if (!payload) { return; }
      switch (type) {
        case 'title.click':
/*        Nuovo flusso decommentare quando sono pronte le api  
          if(!this.mediatorMessages$) {
            this.mediatorMessages$ = payload.mediator.register('title');
            this.mediatorMessages$.subscribe(data => {
              if(data && data.type === 'get-pdf'){
                const fileName = 'dossier-commerciale-' + Utils.slug(payload.name, 3) + '.pdf';
                this.requestFun(payload).subscribe((data) => this.responseFun(data, fileName));
              }
            })
          }
          payload.mediator.sendMessage('dc-modal-layout','show-confirm', this.createModalContent(payload));
          */
          const fileName = 'dossier-commerciale-' + Utils.slug(payload.name, 3) + '.pdf';
          this.requestFun(payload).subscribe((data) => this.responseFun(data, fileName));
          break;   
        default:
          break;
      }
    });
  }

  private requestFun = (payload): Observable<any> => {
    const sector = payload.communication.config.get('sector'); 
    let printUrl = environment.print_url;
    printUrl += 'print/' + payload.id + '/' + payload.id;
    printUrl += '/' + payload.auth.getToken();
    printUrl = sector ? `${printUrl}?sector=${sector}` : printUrl;
    
    this.dataSource.waiting(true);
    return payload.communication.request$('getPrint', {
      params: { pageToScreenshot: printUrl },
      onError: (error) => {
        console.error(error),
          this.dataSource.waiting(false);
      },
      httpOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'x-api-key': payload.auth.apiKey(),
          'x-amz-security-token': payload.auth.getToken()
        })
      }
    });
  }

  private responseFun = (data, fileName) => {
    const byteArray = new Uint8Array(data.buffer.data);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
    this.dataSource.waiting(false);
  }
  
  private createModalContent(payload){
    if(!!payload.communication.config && !!payload){
      return {
        close: true,
        title: {text:'Stampa Dossier Commerciale'},
        text: 'La stampa del dossier ti costerà <N> dei <X> crediti disponibili. Clicca per procedere.',
        actions: [{
          label: 'Prosegui',
          payload: true
        }]
      } 
    }else{ 
      return {
        close: true,
        title: { text: 'Stampa non consentita'},
        text: 'Spiacenti, non sei abilitato a stampare questo dossier oppure hai terminato i crediti.',
        actions: [{
          label: 'Prosegui',
          payload: true
        }]
      }
   
    }

  }

}

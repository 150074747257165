import { HomeLayoutDS } from './home-layout.ds';
import { HomeLayoutEH } from './home-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';
import { DcItitleT1DS, DcDatawT1DS } from '../../data-sources';
import { DcItitleT2DS } from 'src/app/data-sources/dc-ititle-t2.ds';
import { DcChartT2DS } from 'src/app/data-sources/dc-chart-t2.ds';
import { DcItitleT3DS } from 'src/app/data-sources/dc-ititle-t3.ds';
import { DcChartT3DS } from 'src/app/data-sources/dc-chart-t3.ds';
import { DcDatawT3DS } from 'src/app/data-sources/dc-dataw-t3.ds';
import { DcItitleT5DS } from 'src/app/data-sources/dc-ititle-t5.ds';
import { DcChartT5DS } from 'src/app/data-sources/dc-chart-t5.ds';

export const HomeLayoutConfig = {
  layoutId: 'dc-home-layout',
  widgets: [
    {
      id: 'dc-home'
    },
    {
      id: 'title'
    },
    {
      id: 'dc-nav'
    },
    {
      id: 'dc-chart-t1a'
    },
    {
      id: 'dc-chart-t1b'
    },
    {
      id: 'dc-chart-t1c'
    },
    {
      id: 'dc-chart-t1d'
    },
    {
      id: 'dc-metadata-t1a'
    },
    {
      id: 'dc-dataw-t1a',
      dataSource: DcDatawT1DS,
      options: { key: 'Dipendenti' }
    },
    {
      id: 'dc-dataw-t1b',
      dataSource: DcDatawT1DS,
      options: { key: 'Ricavi' }
    },
    {
      id: 'dc-dataw-t1c',
      dataSource: DcDatawT1DS,
      options: { key: 'Età' }
    },
    {
      id: 'dc-table-t1a'
    },
    {
      id: 'dc-table-t1b'
    },
    {
      id: 'dc-ititle-t1a',
      dataSource: DcItitleT1DS,
      options: { key: 'Dipendenti' }
    },
    {
      id: 'dc-ititle-t1b',
      dataSource: DcItitleT1DS,
      options: { key: 'Ricavi' }
    },
    {
      id: 'dc-ititle-t1c',
      dataSource: DcItitleT1DS,
      options: { key: 'Età' }
    },
    {
      id: 'dc-chart-t2a',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2a' }
    },
    {
      id: 'dc-ititle-t2a',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2a', label: 'Ricavi', sublabel: 'K€' }
    },
    {
      id: 'dc-chart-t2b',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2b' }
    },
    {
      id: 'dc-ititle-t2b',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2b', label: 'EBITDA', sublabel: 'K€' }
    },
    {
      id: 'dc-chart-t2c',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2c' }
    },
    {
      id: 'dc-ititle-t2c',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2c', label: 'Utile', sublabel: 'K€' }
    },
    {
      id: 'dc-chart-t2d',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2d' }
    },
    {
      id: 'dc-ititle-t2d',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2d', label: 'Posizione finanziaria netta', sublabel: 'K€' }
    },
    {
      id: 'dc-chart-t2e',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2e' }
    },
    {
      id: 'dc-ititle-t2e',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2e', label: 'Patrimonio netto', sublabel: 'K€' }
    },
    {
      id: 'dc-chart-t2f',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2f' }
    },
    {
      id: 'dc-ititle-t2f',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2f', label: 'Cash flow dell\'esercizio', sublabel: 'K€' }
    },
    {
      id: 'dc-chart-t2g',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2g' }
    },
    {
      id: 'dc-ititle-t2g',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2g', label: 'EBITDA %', sublabel: '% su Ricavi' }
    },
    {
      id: 'dc-chart-t2h',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2h' }
    },
    {
      id: 'dc-ititle-t2h',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2h', label: 'ROE %', sublabel: 'Utile/Patrimonio netto %' }
    },
    {
      id: 'dc-chart-t2i',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2i' }
    },
    {
      id: 'dc-ititle-t2i',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2i', label: 'Utile %', sublabel: '% su Ricavi' }
    },
    {
      id: 'dc-chart-t2j',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2j' }
    },
    {
      id: 'dc-ititle-t2j',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2j', label: 'Patrimonio netto / Totale attivo', sublabel: '%' }
    },
    {
      id: 'dc-chart-t2k',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2k' }
    },
    {
      id: 'dc-ititle-t2k',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2k', label: 'Capitale circolante operativo', sublabel: 'K€' }
    },
    {
      id: 'dc-chart-t2l',
      dataSource: DcChartT2DS,
      options: { key: 'dc-chart-t2l' }
    },
    {
      id: 'dc-ititle-t2l',
      dataSource: DcItitleT2DS,
      options: { key: 'dc-ititle-t2l', label: 'Posizione finanziaria netta / EBITDA', sublabel: 'Moltiplicatore' }
    },
    {
      id: 'dc-ititle-t3a',
      dataSource: DcItitleT3DS,
      options: { key: 'dc-ititle-t3a', label: 'Fatturato Italia', sublabel: 'Aggiornato al ${last_update}' }
    },
    {
      id: 'dc-ititle-t3b',
      dataSource: DcItitleT3DS,
      options: { key: 'dc-ititle-t3b', label: 'Produzione', sublabel: 'in Mln€, dal ${start_year} al ${end_year}' }
    },
    {
      id: 'dc-ititle-t3c',
      dataSource: DcItitleT3DS,
      options: { key: 'dc-ititle-t3c', label: 'Import', sublabel: 'in Mln€, dal ${start_year} al ${end_year}' }
    },
    {
      id: 'dc-ititle-t3d',
      dataSource: DcItitleT3DS,
      options: { key: 'dc-ititle-t3d', label: 'Propensione export', sublabel: 'Aggiornato al ${last_update}' }
    },
    {
      id: 'dc-ititle-t3e',
      dataSource: DcItitleT3DS,
      options: { key: 'dc-ititle-t3e', label: 'Export', sublabel: 'in Mln€, dal ${start_year} al ${end_year}' }
    },
    {
      id: 'dc-ititle-t3f',
      dataSource: DcItitleT3DS,
      options: { key: 'dc-ititle-t3f', label: 'Mercato interno', sublabel: 'in Mln€, dal ${start_year} al ${end_year}' }
    },
    {
      id: 'dc-chart-t3b',
      dataSource: DcChartT3DS,
      options: { key: 'dc-chart-t3b' }
    },
    {
      id: 'dc-chart-t3c',
      dataSource: DcChartT3DS,
      options: { key: 'dc-chart-t3c' }
    },
    {
      id: 'dc-chart-t3e',
      dataSource: DcChartT3DS,
      options: { key: 'dc-chart-t3e' }
    },
    {
      id: 'dc-chart-t3f',
      dataSource: DcChartT3DS,
      options: { key: 'dc-chart-t3f' }
    },
    {
      id: 'dc-chart-t3d'
    },
    {
      id: 'dc-dataw-t3a',
      dataSource: DcDatawT3DS,
      options: { key: 'fatturato', label: 'Variazione su anno precedente' }
    },



    {
      id: 'dc-ititle-t5a',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5a', label: 'Trend fatturato', sublabel: 'anno ${start_common_year} = 100' }
    },
    {
      id: 'dc-chart-t5a',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5a' }
    },
    {
      id: 'dc-ititle-t5b',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5b', label: 'Trend EBITDA', sublabel: 'anno ${start_common_year} = 100' }
    },
    {
      id: 'dc-chart-t5b',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5b' }
    },
    {
      id: 'dc-ititle-t5c',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5c', label: 'EBITDA margin', sublabel: '%' }
    },
    {
      id: 'dc-chart-t5c',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5c', measureunit: ' %' }
    },
    {
      id: 'dc-ititle-t5d',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5d', label: 'ROE', sublabel: '%' }
    },
    {
      id: 'dc-chart-t5d',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5d', measureunit: ' %' }
    },
    {
      id: 'dc-ititle-t5e',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5e', label: 'Rapporto di indebitamento', sublabel: '%' }
    },
    {
      id: 'dc-chart-t5e',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5e', measureunit: ' %' }
    },
    {
      id: 'dc-ititle-t5f',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5f', label: 'Indice di copertura debiti', sublabel: '%' }
    },
    {
      id: 'dc-chart-t5f',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5f', measureunit: ' %' }
    },
    {
      id: 'dc-ititle-t5g',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5g', label: 'Circolante operativo / Ricavi', sublabel: '%' }
    },
    {
      id: 'dc-chart-t5g',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5g', measureunit: ' %' }
    },
    {
      id: 'dc-ititle-t5h',
      dataSource: DcItitleT5DS,
      options: { key: 'dc-ititle-t5h', label: 'Indice rotazione crediti', sublabel: 'gg' }
    },
    {
      id: 'dc-chart-t5h',
      dataSource: DcChartT5DS,
      options: { key: 'dc-chart-t5h', measureunit: ' gg' }
    },

  ],
  layoutDS: HomeLayoutDS,
  layoutEH: HomeLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {

  }
};

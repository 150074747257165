import { EventHandler } from '@n7-frontend/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, Observable } from 'rxjs';
import { mock } from './mock';
import { ComponentMediatorService, IMediatorMessage } from 'src/app/services/component-mediator.service';

export class HomeLayoutEH extends EventHandler {

  private dataCache = { id: '', data: {} };
  private communication;
  private navRouter;
  private auth;
  private router;
  private tmpData = null;
  private mediatorMessage$: Observable<IMediatorMessage>;
  private mediator: ComponentMediatorService;

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'dc-home-layout.init':
          this.dataSource.onInit(payload);
          this.init(payload);
          this.listenRoute();
          break;
      }
    });
  }

  private init(payload) {
    this.communication = payload.communication;
    this.navRouter = payload.navRouter;
    this.auth = payload.auth;
    this.router = payload.router;
    this.mediator = payload.mediator;
    this.mediatorMessage$ = this.mediator.register('dc-home-layout');
    this.mediatorMessage$.subscribe(this.treatMediatorMessages);
  }

  private listenRoute() {
    this.router.paramMap.subscribe(x => {
      const id = x.get('id');
      const companyName = x.get('name');
      const navPage = x.get('nav');
      if (this.dataCache.id === id) {
        this.dataSource.updateNav(this.dataCache.data, companyName, navPage, id);
      } else {
        let requestFun = this.getCompanyData(id);
        if (environment.useMock) {
          requestFun = of(mock);
        }
        requestFun.subscribe(data => {

          const currentSector = this.communication.config.get('sector');
          if (currentSector) {
            this.dataCache.data = data;
            this.dataCache.id = id;
            this.transformData(data, currentSector);
            this.dataSource.updateWidgets(data, id, companyName, navPage);
          } else {
            const hasSector = data && data.company_basic_info && data.company_basic_info.sector_name && data.company_basic_info.sector_name.length > 0;
            const hasManySectors = hasSector && data.company_basic_info.sector_name.length > 1;
            if (hasManySectors) {
              this.tmpData = { data, id, navPage, companyName };
              this.mediator.sendMessage('dc-modal-layout', 'show-radio', data);
            } else {
              this.dataCache.data = data;
              this.dataCache.id = id;
              const sectorId = hasSector && data.company_basic_info.sector_name[0].id;
              this.communication.config.set('sector', sectorId);
              this.transformData(data, sectorId);
              this.dataSource.updateWidgets(data, id, companyName, navPage);
            }
          }
        })
      }
    })
  }

  private getCompanyData(id) {
    return this.communication.request$('getCompany', {
      urlParams: id,
      params: { companyId: id },
      onError: (error) => {
        console.log('getCompany error', error);
        switch (error.status) {
          case 404:
            this.navRouter.navigate(['']);
            break;
          case 500:
            this.auth.setToken(null);
            this.auth.setRefreshToken(null);
            this.navRouter.navigate(['']); // go home
            break;
          default:
            if (error.status || error.status === 0) {
              this.navRouter.navigate(['error']); // GO error????
            }
            break;
        }
      },
      httpOptions: {
        headers: new HttpHeaders({
          'x-api-key': this.auth.apiKey(),
          'x-amz-security-token': this.auth.getToken()
        })
      }
    });
  }

  private transformData(data, sectorId) {
    const sectorFun = (sector) => sector.id === sectorId;
    const sector_name = data.company_basic_info.sector_name.find(sectorFun);
    if (sector_name ) {
      data.company_basic_info.sector_name = sector_name.value;

      const sector_main_characteristics = data.company_basic_info.sector_main_characteristics.find(sectorFun);
      data.company_basic_info.sector_main_characteristics = (sector_main_characteristics && sector_main_characteristics.value) || null;

      const isotype_info = data.company_basic_info.isotypes_info.find(sectorFun);
      if (isotype_info) {
        data.company_basic_info.isotype_main_needs = isotype_info.value.isotype_main_needs;
        data.company_basic_info.isotype_secondary_needs = isotype_info.value.isotype_secondary_needs;
        data.company_basic_info.isotype_name = isotype_info.value.isotype_name;
        data.company_basic_info.isotype_description = isotype_info.value.isotype_description;
      }

      if (data.market_analysis) {
        if (data.market_analysis.economical_data) {
          const economical_data = data.market_analysis.economical_data.find(sectorFun);
          data.market_analysis.economical_data = (economical_data && economical_data.value) || {};
        }
        if (data.market_analysis.critical_variables) {
          const critical_variables = data.market_analysis.critical_variables.find(sectorFun);
          data.market_analysis.critical_variables = (critical_variables && critical_variables.value) || {};
        }
      }

      if (data.menace_opportunities) {
        const menace_opportunities = data.menace_opportunities.find(sectorFun);
        data.menace_opportunities = (menace_opportunities && menace_opportunities.value) || {};
      }
    } else {
      data.company_basic_info.sector_name = null;
      data.company_basic_info.sector_main_characteristics = null;
      const isotype_info = data.company_basic_info.isotypes_info;
      if (isotype_info.length === 1) {
        data.company_basic_info.isotype_main_needs = isotype_info[0].isotype_main_needs;
        data.company_basic_info.isotype_secondary_needs = isotype_info[0].isotype_secondary_needs;
        data.company_basic_info.isotype_name = isotype_info[0].isotype_name;
        data.company_basic_info.isotype_description = isotype_info[0].isotype_description;
      }
      if(data.market_analysis.economical_data && data.market_analysis.economical_data.length === 1){
        data.market_analysis.economical_data = data.market_analysis.economical_data[0];
      }else{
        data.market_analysis.economical_data = {}
      }
      if(data.market_analysis.critical_variables && data.market_analysis.critical_variables.length === 1){
        data.market_analysis.critical_variables = data.market_analysis.critical_variables[0];
      }else{
        data.market_analysis.critical_variables = {};
      }
      if(data.menace_opportunities && data.menace_opportunities.length === 1){
        data.menace_opportunities = data.menace_opportunities[0];
      }else{
        data.menace_opportunities = {};
      }
    }
  }

  private treatMediatorMessages = ({ payload, type }: IMediatorMessage) => {
    switch (type) {
      case 'radio-result':
        this.communication.config.set('sector', payload);
        this.transformData(this.tmpData.data, payload);
        this.dataCache.data = this.tmpData.data;
        this.dataCache.id = this.tmpData.id;
        const companyName = this.tmpData.companyName;
        const navPage = this.tmpData.navPage;
        this.tmpData = null;
        this.dataSource.updateWidgets(this.dataCache.data, this.dataCache.id, companyName, navPage);
        break;
      default:
        break;
    }
  }
}

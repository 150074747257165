import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractLayout, CommunicationService, ConfigurationService } from '@n7-frontend/boilerplate';
import { HomeLayoutConfig as config } from './home-layout.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { ChartService } from 'src/app/services/chart.service';
import { Utils } from 'src/app/data-sources/utils';
import { ComponentMediatorService } from 'src/app/services/component-mediator.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dc-home-layout',
  templateUrl: './home-layout.html'
})
export class HomeLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {

  public navigation = 'tabHome';
  public loading = true;
  public hasData = false;
  public showSectorModal = false;

  public hasDivision = true;
  public hasSectorAnalysis = true;
  public hasIsotype = true;
  public hasPrimaryNeeds = true;
  public hasKpi = true;
  public customKpi = false;

  public showLineeGuidaComm = false;

  public pdfLogo = null;
  public pdfPages = null;
  public pdfFooterText = null;

  public titleplaceholder = {
    blocks: [
      { classes: 'dc-title-loading' },
      // { classes: 'thumb-placeholder' },
      { classes: 'dc-text-loading' },
    ]
  };
  public navplaceholder = {
    blocks: [
      { classes: '' },
      { classes: '' },
      { classes: '' },
      { classes: '' },
      { classes: '' },
      { classes: '' },
    ]
  };
  public pageplaceholder = {
    blocks: [
      { classes: 'title-loading' },
      { classes: 'text-loading' },
      { classes: 'page-loading' },
      { classes: 'page-loading' },
      { classes: 'page-loading' },
      { classes: 'page-loading' },
      { classes: 'page-loading' },
      { classes: 'page-loading' },
      { classes: 'page-loading' },
      { classes: 'page-loading' }
    ]
  };

  constructor(
    private communication: CommunicationService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private configService: ConfigurationService,
    private location: Location,
    private auth: AuthService,
    private chartService: ChartService,
    private mediator: ComponentMediatorService
  ) {
    super(config);
  }

  protected initPayload() {
    return {
      communication: this.communication,
      router: this.activatedRouter,
      navRouter: this.router,
      auth: this.auth,
      mediator: this.mediator
    };
  }

  ngOnInit() {
    this.initHeader();
    this.initPdfParams();
    this.initSectionsParams();
    this.onInit();
    this.lb.widgets['dc-home'].ds.out$.subscribe(data => {
      if (data) {
        this.hasData = true;
        this.hasDivision = !!data.company_basic_info.division;
        this.hasSectorAnalysis = data.company_basic_info.sector_analysis;
        this.hasIsotype = !!data.company_basic_info.isotype_name;
        this.hasPrimaryNeeds = data.company_basic_info.isotype_main_needs && data.company_basic_info.isotype_main_needs.length;
        this.hasKpi = Utils.hasKpiData(data.market_analysis.economical_data);
        this.customKpi = typeof data.company_basic_info.sector_name === "string" && data.company_basic_info.sector_name === "";
      }
    });
    this.activatedRouter.paramMap.subscribe(navParams => {
      const nav = navParams.get('nav');
      if (!this.auth.hasToken()) {
        this.router.navigate(['error']);
      } else {
        this.navigate(nav);
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy();
    this.mediator.remove('dc-home-layout');
  }

  navigate(payload) {
    let tab = 'tabHome';
    switch (payload) {
      case 'home':
        tab = 'tabHome';
        break;
      case 'balance':
        tab = 'tabBalance';
        break;
      case 'analysis':
        tab = 'tabAnalysis';
        break;
      case 'manace':
        tab = 'tabManace';
        break;
      case 'kpi':
        tab = 'tabKpi';
        break;
      case 'lines':
        tab = 'tabLines';
        break;
      case 'print':
        tab = 'print';
        break;
    }
    this.navigation = tab;
    this.loading = false;
  }

  readyPrint() {
    return this.chartService.isReadyForPrint();
  }

  private initPdfParams(){
    const pdfConfig = this.configService.get('pdf');
    if(!pdfConfig) return;

    if(pdfConfig.logo_pdf){
      this.pdfLogo = pdfConfig.logo_pdf;
    }
    if(pdfConfig.pages){
      this.pdfPages = pdfConfig.pages;
    }
    if(pdfConfig.footer_text){
      this.pdfFooterText = pdfConfig.footer_text;
    }
  }

  private initSectionsParams(){
    const sectionsConfig = this.configService.get('sections');
    if(!sectionsConfig) return;
    this.showLineeGuidaComm = sectionsConfig.enable_linee_guida_commerciali;
  }

  private initHeader(){
    const headerConfig = this.configService.get('header');
    if(!headerConfig) return;
    const color = headerConfig.bg_color;
    const element:HTMLElement = (document.querySelector('n7-header header') as HTMLElement);
    if(!!element && !!color){
      element.style.background = `#${color}`.toLowerCase();
    }
  }

}

import { Component, OnInit, Output, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'app-tab1',
    templateUrl: './tab1.component.html'
})
export class Tab1Component implements OnInit {

    public images = [];
    public data: any = {};

    @Input() lb: any;
    @Input() print: boolean;

    constructor(
        private chartService: ChartService
    ) { }

    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['dc-home'].ds.out$.subscribe(data => {
            this.data = data;
            if (data) {
                setTimeout(() => {
                    if (this.print) {
                        this.chartService.convertToImage(['dc-chart-t1a', 'dc-chart-t1b', 'dc-chart-t1c', 'dc-chart-t1d'], this.images).then(() => {
                            this.chartService.readyForPrint();
                        });
                    }
                }, this.chartService.waitTime);
            }
        });
    }

    isDate(d) {
        return d && d != '1900-01-01';
    }

}

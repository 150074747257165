import { EventHandler } from '@n7-frontend/core';
import { Observable } from 'rxjs';
import { IMediatorMessage } from 'src/app/services/component-mediator.service';

export class ModalLayoutEH extends EventHandler {

  private mediatorMessage$: Observable<IMediatorMessage>;
  private mediator;

  private selectedOption = null;

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'dc-modal-layout.init':
          this.init(payload);
          break;
        case 'dc-modal-layout.submit':
          if (payload.type === 'radio') {
            if (!!this.selectedOption) {
              this.mediator.sendMessage('dc-home-layout', 'radio-result', this.selectedOption);
              this.dataSource.hideModal();
              this.selectedOption = null;
            }
          } else if (payload.type === 'confirm') {
            this.mediator.sendMessage('title', 'get-pdf', this.selectedOption);
            this.dataSource.hideModal();
          }

          break;
        case 'dc-modal-layout.close':
          this.dataSource.hideModal();
          this.selectedOption = null;
          break;
      }
    });
    this.outerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'dc-radio-modal.change':
          this.selectedOption = payload.value;
          break;
      }
    })
  }

  private init(payload) {
    this.mediator = payload.mediator;
    this.mediatorMessage$ = this.mediator.register('dc-modal-layout');
    this.mediatorMessage$.subscribe(this.treatMediatorMessage);
  }

  private treatMediatorMessage = ({ type, payload }: IMediatorMessage) => {
    switch (type) {
      case 'show-radio':
        const title = payload.company_basic_info.general_data[0].value;
        const logo = payload.company_basic_info.company_logo;
        const options = payload.company_basic_info.sector_name;
        this.selectedOption = options[0].id;
        this.dataSource.showRadio({ title: { text: title, logo: logo }, options: options });
        break;
      case 'show-confirm':
        this.selectedOption = null;
        this.dataSource.showConfirm(payload);
        break;
      default:
        break;
    }
  }


}

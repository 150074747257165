export const environment = {
  production: false,
  sso_url:
    "https://u.atoka.io/openid/authorize?client_id=567406&redirect_uri=https://dossier-commerciale-u.atoka.io/sso_return&response_type=token&response_type=code&scope=atoka-dossier-commerciale openid profile email atoka",
  print_url: "https://dossier-commerciale-u.atoka.io/",
  api_key: "",
  codeToToken_url:
    "https://9dsrnff9rk.execute-api.eu-west-1.amazonaws.com/stage/getAtokaAccessToken",
  config_url: "https://9dsrnff9rk.execute-api.eu-west-1.amazonaws.com/stage/getCompanyConfiguration",
  file_url: "https://9dsrnff9rk.execute-api.eu-west-1.amazonaws.com/stage/retrieveStaticFile",
  sectormain_printlength: 5500,
  useMock: false
};

import { Component, OnInit, Output, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';
import { stringify } from 'querystring';
import { ReturnStatement } from '@angular/compiler';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-tab3',
    templateUrl: './tab3.component.html'
})
export class Tab3Component implements OnInit {

    @Input() lb: any;
    @Input() print: boolean;

    public data = {};
    public images = [];
    public splitted = [];

    constructor(
        private chartService: ChartService) {
    }

    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['dc-home'].ds.out$.subscribe(data => {
            this.data = data;
            if (data) {
                setTimeout(() => {
                    if (this.print) {
                        this.chartService.convertToImage(
                            [
                                'dc-chart-t3b',
                                'dc-chart-t3c',
                                'dc-chart-t3d',
                                'dc-chart-t3e',
                                'dc-chart-t3f'], this.images).then(() => {
                                    this.chartService.readyForPrint();
                                });
                    }
                }, this.chartService.waitTime);
                this.splitted = this.split(data.company_basic_info.sector_main_characteristics);
            }
        });
    }

    split(val: string = null) {
        let ret = [];
        if (val) {
            const words = val.split(' ');
            while (words.length > 0) {
                let slice = '';
                while (slice.length < environment.sectormain_printlength && words.length) {
                    slice += words.shift() + ' ';
                }
                ret.push(slice);
            }
        }
        return ret;
    }

}

import { DataSource } from '@n7-frontend/core';
import { IDataWidgetData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcDatawT3DS extends DataSource {

  protected transform(data, option): IDataWidgetData {
    if (!data || !data.market_analysis) {
      return;
    }

    let increment = 0;
    let lastValue = '';

    switch (option['key'].toLowerCase()) {
      case 'fatturato':
        {
          const rev = data.market_analysis.economical_data.revenues_italy;
          if (rev) {
            const revenues = Utils.sortByYear(rev.values);
            const MODIFIER = 2; //TODO controllare valore del modificatore per tralassciare gli ultimi x anni
            const l = revenues.length - MODIFIER;
            if (l >= 0 && revenues[l - 1].value) {
              const last = revenues[l - 1].value;
              const beforelast = l > 1 ? revenues[l - 2].value : 1;
              increment = Math.round((last - beforelast) * 100 / beforelast);
              lastValue = revenues[l - 1].value + 'M€';
            }
          }
        }
        break;
    }


    if (!lastValue) {
      return {
        text: null,
        payload: { empty: true }
      };
    }

    return {
      // icon: 'n7-icon-earth',
      text: lastValue,
      subtitle: {
        text: option['label'],
        icon: increment > 0 ? 'n7-icon-caret-up' : 'n7-icon-caret-down',
        value: increment + '%',
        payload: 'view percent tooltip '
      },
      payload: 'view earth tooltip',
      classes: increment > 0 ? 'is-positive' : 'is-negative'
    };
  }
}

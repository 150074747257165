import { DataSource } from '@n7-frontend/core';
import { IChartData } from '@n7-frontend/components';
import { Utils } from './utils';

export class DcChartT1dDS extends DataSource {

  constructor() { super(); }

  MAX_VALUES = 3;

  protected transform(data): IChartData {
    if (!data || !data.shareholders) {
      return;
    }

    const values = [];
    const categs = [];
    const shareholders = [];

    let totalPerc = 0;
    let totalVal = 0;
    data.shareholders.values.sort((a, b) => a.percentage > b.percentage ? -1 : 1).forEach((x, idx) => {
      if (idx < this.MAX_VALUES) {
        shareholders.push(x);
      } else {
        totalPerc += x.percentage;
        totalVal += x.value;
      }
    });
    if (totalVal > 0) {
      shareholders.push({
        label: 'Altro',
        percentage: totalPerc,
        value: totalVal
      });
    }

    shareholders.forEach(x => {
      values.push(x.value);
      categs.push(x.label);
    });

      
    if (!values || values.length === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }

    
    const chartOption = {
      chart: {
        id: 'dc-chart-t1d',
        fontFamily: 'Open Sans',
        animations: {
          enabled: data.noAnimation ? false : true
        },
        type: 'donut',
        height: 190,
        width: 450,
        toolbar: {
          show: false
        }
      },
      colors: Utils.palette2,
      stroke: {
        show: false,
        width: 0
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        width: 220,
        markers: {
          width: 10,
          height: 10,
          radius: 10,
        },
      },
      series: values,
      labels: categs,
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const html = Utils.getTooltip(
            shareholders[seriesIndex].label,
            Utils.approx(shareholders[seriesIndex].percentage),
            Utils.bigNumberConverter(shareholders[seriesIndex].value),
            'partecipazioni'
          );
          return html;
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: 'dc-chart-t1d',
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}

import { Component, OnInit, Output, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'app-tab2',
    templateUrl: './tab2.component.html'
})
export class Tab2Component implements OnInit {

    @Input() lb: any;
    @Input() print: boolean;
    @Output() readyForPrint = false;

    public images = [];
    public data = {};

    public row0 = [['dc-ititle-t2a', 'dc-chart-t2a'], ['dc-ititle-t2b', 'dc-chart-t2b'], ['dc-ititle-t2c', 'dc-chart-t2c']];
    public row1 = [['dc-ititle-t2d', 'dc-chart-t2d'], ['dc-ititle-t2e', 'dc-chart-t2e'], ['dc-ititle-t2f', 'dc-chart-t2f']];
    public row2 = [['dc-ititle-t2g', 'dc-chart-t2g'], ['dc-ititle-t2h', 'dc-chart-t2h'], ['dc-ititle-t2i', 'dc-chart-t2i']];
    public row3 = [['dc-ititle-t2j', 'dc-chart-t2j'], ['dc-ititle-t2k', 'dc-chart-t2k'], ['dc-ititle-t2l', 'dc-chart-t2l']];

    constructor(
        private chartService: ChartService) { }

    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['dc-home'].ds.out$.subscribe(data => {
            this.data = data;
            if (data) {
                setTimeout(() => {
                    if (this.print) {
                        this.chartService.convertToImage(
                            [
                                'dc-chart-t2a',
                                'dc-chart-t2b',
                                'dc-chart-t2c',
                                'dc-chart-t2d',
                                'dc-chart-t2e',
                                'dc-chart-t2f',
                                'dc-chart-t2g',
                                'dc-chart-t2h',
                                'dc-chart-t2i',
                                'dc-chart-t2j',
                                'dc-chart-t2k',
                                'dc-chart-t2l'], this.images).then(() => {
                                    this.chartService.readyForPrint();
                                });
                    }
                }, this.chartService.waitTime);
            }
        });
    }

}

import { DataSource } from '@n7-frontend/core';
import { InputRadioData } from '../components/input-radio/input-radio';
export class DcRadioModalDS extends DataSource {
    protected transform(data): InputRadioData {
        if(!data || !data.options) {
            return;
        }
        
        const options = data.options.map( element => {return {label: element.value, value: element.id}});
        options[0].selected = true;
        return {
            id: 'dc-radio-modal',
            options: options
        };
    }
}

import { Component, OnInit, Output, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'app-tab5',
    templateUrl: './tab5.component.html'
})
export class Tab5Component implements OnInit {

    @Input() lb: any;
    @Input() print: boolean;

    public images = [];
    public data: any = {};

    public graphs = [
        ['dc-ititle-t5a', 'dc-chart-t5a'], ['dc-ititle-t5b', 'dc-chart-t5b'],
        ['dc-ititle-t5c', 'dc-chart-t5c'], ['dc-ititle-t5d', 'dc-chart-t5d'],
        ['dc-ititle-t5e', 'dc-chart-t5e'], ['dc-ititle-t5f', 'dc-chart-t5f'],
        ['dc-ititle-t5g', 'dc-chart-t5g'], ['dc-ititle-t5h', 'dc-chart-t5h'],
    ];

    constructor(
        private chartService: ChartService) { }

    ngOnInit() {
        this.chartService.notReadyForPrint();
        this.lb.widgets['dc-home'].ds.out$.subscribe(data => {
            this.data = data;
            if (data) {
                setTimeout(() => {
                    if (this.print) {
                        const chartsId = [];
                        this.graphs.forEach(g => {
                            chartsId.push(g[1]);
                        });
                        this.chartService.convertToImage(chartsId, this.images).then(() => {
                            this.chartService.readyForPrint();
                        });
                    }
                }, this.chartService.waitTime);
            }
        });
    }

}

import { DataSource } from '@n7-frontend/core';
import { IDataWidgetData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcDatawT1DS extends DataSource {


  protected transform(data, option): IDataWidgetData {
    if (!data) {
      return;
    }

    let increment = 0;
    let lastValue = '';
    let showSubtitle = true;

    const subText = option['key'];
    if (subText) {
      switch (subText.toLowerCase()) {
        case 'ricavi':
          {
            const l = data.economical_data.revenues.values.length;
            const last = l > 0 ? data.economical_data.revenues.values[l - 1].value : null;
            const beforelast = l > 1 ? data.economical_data.revenues.values[l - 2].value : 1;
            increment = Math.round((last - beforelast) * 100 / beforelast);
            if (data.economical_data.revenues.last_value) {
              lastValue = Utils.bigNumberConverter(data.economical_data.revenues.last_value) + '€';
            }
          }
          break;
        case 'dipendenti':
          {
            const l = data.company_basic_info.employees.values.length;
            const last = data.company_basic_info.employees.values[l - 1] ? data.company_basic_info.employees.values[l - 1].value : 0;
            const beforelast = l > 1 ? data.company_basic_info.employees.values[l - 2].value : 1;
            increment = Math.round((last - beforelast) * 100 / beforelast);
            lastValue = data.company_basic_info.employees.last_value;
          }
          break;
        case 'età':
          {
            // const bday = data.company_basic_info.people.items[0].birthdate;
            const bday = data.company_basic_info.general_data.find(x => x.label === 'Sul mercato da').value;
            if (bday) { lastValue = Utils.getAge(bday) + ' anni'; }
            showSubtitle = false;
          }
          break;
      }
    }

    if (!lastValue) { return; }

    if (!isFinite(increment)) { showSubtitle = false; }

    return {
      // icon: 'n7-icon-earth',
      text: lastValue,
      subtitle: showSubtitle ? {
        text: subText,
        icon: increment > 0 ? 'n7-icon-caret-up' : 'n7-icon-caret-down',
        value: increment + '%',
        payload: 'view percent tooltip '
      } : null,
      payload: 'view earth tooltip',
      classes: increment > 0 ? 'is-positive' : 'is-negative'
    };
  }
}

import { LayoutDataSource } from '@n7-frontend/core';
import { HttpHeaders } from '@angular/common/http';
import { mock } from './mock';
import { of } from 'rxjs/internal/observable/of';
import { environment } from '../../../environments/environment';

export class HomeLayoutDS extends LayoutDataSource{
  
  private communication;
  private navRouter;
  private auth;
  private mediator;

  // private dataCache = { id: '', data: {} };

  private showLineeGuidaComm = false;
  private showPdfButton = false;


  onInit(payload) {
    this.communication = payload.communication;
    this.navRouter = payload.navRouter;
    this.auth = payload.auth;
    this.mediator = payload.mediator;
    const sectionsConfig = payload.communication.config.get('sections');
    this.showLineeGuidaComm = sectionsConfig && sectionsConfig.enable_linee_guida_commerciali;
  }

  public updateWidgets = (data, id, companyName, navPage) => {
    if (data.company_basic_info.legal_class.toLowerCase() === 'altre forme') {
      this.navRouter.navigate(['']);
    } else if (data.company_basic_info.legal_class.toLowerCase() === 'società di persone' || data.company_basic_info.legal_class.toLowerCase() === 'societa di persone') {
      data.societaDiPersone = true;
    } else if (data.company_basic_info.legal_class.toLowerCase() === 'impresa individuale') {
      data.impresaIndividuale = true;
    }

    if (data.company_basic_info.division.toLowerCase() === 'holding') {
      data.holding = true;
    }

    if (navPage.indexOf('print') === 0) {
      data.noAnimation = true;
      data.market_analysis.economical_data.noAnimation = true;
      data.company_basic_info.noAnimation = true;
      data.economical_data.noAnimation = true;
    }
    this.one('dc-home').update(data);
    this.one('title').update({ data: data.company_basic_info, communication: this.communication, companyId: id, auth: this.auth, 
      mediator: this.mediator,
      showPdfButton: this.showPdfButton });
    this.one('dc-chart-t1a').update(data.company_basic_info);
    this.one('dc-chart-t1b').update(data.economical_data);
    this.one('dc-chart-t1c').update(data.company_basic_info);
    this.one('dc-chart-t1d').update(data.company_basic_info);
    this.one('dc-nav').update({ navData: data, cName: companyName, nav: navPage, cId: id, showLineeGuidaComm: this.showLineeGuidaComm });
    this.one('dc-metadata-t1a').update(data.company_basic_info);
    this.one('dc-dataw-t1a').update(data);
    this.one('dc-dataw-t1b').update(data);
    this.one('dc-dataw-t1c').update(data);
    this.one('dc-table-t1a').update(data.company_basic_info);
    this.one('dc-table-t1b').update(data.company_basic_info);
    this.one('dc-ititle-t1a').update(data);
    this.one('dc-ititle-t1b').update(data);
    this.one('dc-ititle-t1c').update(data);
    this.some([
      'dc-ititle-t2a',
      'dc-ititle-t2b',
      'dc-ititle-t2c',
      'dc-ititle-t2d',
      'dc-ititle-t2e',
      'dc-ititle-t2f',
      'dc-ititle-t2g',
      'dc-ititle-t2h',
      'dc-ititle-t2i',
      'dc-ititle-t2j',
      'dc-ititle-t2k',
      'dc-ititle-t2l'
    ]).update(data);
    this.some([
      'dc-chart-t2a',
      'dc-chart-t2b',
      'dc-chart-t2c',
      'dc-chart-t2d',
      'dc-chart-t2e',
      'dc-chart-t2f',
      'dc-chart-t2g',
      'dc-chart-t2h',
      'dc-chart-t2i',
      'dc-chart-t2j',
      'dc-chart-t2k',
      'dc-chart-t2l'
    ]).update(data);
    this.some([
      'dc-ititle-t3a',
      'dc-ititle-t3b',
      'dc-ititle-t3c',
      'dc-ititle-t3d',
      'dc-ititle-t3e',
      'dc-ititle-t3f'
    ]).update(data);
    this.some([
      'dc-chart-t3b',
      'dc-chart-t3c',
      'dc-chart-t3e',
      'dc-chart-t3f',
    ]).update(data);
    this.one('dc-chart-t3d').update(data.market_analysis.economical_data);
    this.one('dc-dataw-t3a').update(data);
    this.some([
      'dc-ititle-t5a',
      'dc-ititle-t5b',
      'dc-ititle-t5c',
      'dc-ititle-t5d',
      'dc-ititle-t5e',
      'dc-ititle-t5f',
      'dc-ititle-t5g',
      'dc-ititle-t5h'
    ]).update(data);
    this.some([
      'dc-chart-t5a',
      'dc-chart-t5b',
      'dc-chart-t5c',
      'dc-chart-t5d',
      'dc-chart-t5e',
      'dc-chart-t5f',
      'dc-chart-t5g',
      'dc-chart-t5h'
    ]).update(data);
  }

  public updateNav = (navData, cName, nav, cId ) =>{
    return  this.one('dc-nav').update({
      navData,
      cName,
      nav,
      cId,
      showLineeGuidaComm: this.showLineeGuidaComm
    });
  }
}

import { DataSource } from '@n7-frontend/core';
import { IInnerTitleData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcItitleT2DS extends DataSource {
  protected transform(data, option): IInnerTitleData {
    if (!data) {
      return;
    }

    let ref = [];
    let label = option['sublabel'];

    switch (option['key'].toLowerCase()) {
      case 'dc-ititle-t2a': {
        ref = data.economical_data.revenues.values;
      }
        break;
      case 'dc-ititle-t2b': {
        ref = data.economical_data.ebitda.values;
      }
        break;
      case 'dc-ititle-t2c': {
        ref = data.economical_data.profit.values;
      }
        break;
      case 'dc-ititle-t2d': {
        ref = data.economical_data.posizione_finanziaria_netta.values;
      }
        break;
      case 'dc-ititle-t2e': {
        ref = data.economical_data.patrimonio_netto.values;
      }
        break;
      case 'dc-ititle-t2f': {
        ref = data.economical_data.cash_flow.values;
      }
        break;
      case 'dc-ititle-t2g': {
        ref = data.economical_data.ebitda_percentage.values;
      }
        break;
      case 'dc-ititle-t2h': {
        ref = data.economical_data.roe_percentage.values;
      }
        break;
      case 'dc-ititle-t2i': {
        ref = data.economical_data.profit_percentage.values;
      }
        break;
      case 'dc-ititle-t2j': {
        ref = data.economical_data.patrimonio_netto_totale_attivo.values;
      }
        break;
      case 'dc-ititle-t2k': {
        ref = data.economical_data.capitale_circolante_netto_operativo.values;
      }
        break;
      case 'dc-ititle-t2l': {
        if (data.economical_data.posizione_finanziaria_netta_ebitda && data.economical_data.posizione_finanziaria_netta_ebitda.values) {
          ref = data.economical_data.posizione_finanziaria_netta_ebitda.values;
        }
      }
        break;
    }

    if (!ref) {
      return null;
    }

    if (label.indexOf('€') >= 0) {
      label = Utils.getSizeLabelForData(ref, true) + '€';
    }
    ref = Utils.sortByYear(ref);
    const start = ref.length > 0 ? ref[0].year : null;
    const end = ref.length > 0 ? ref[ref.length - 1].year : null;

    return {
      title: {
        main: {
          text: option['label']
        },
        secondary: {
          text: start ? `${label} dal ${start} al ${end}` : ''
        }
      }
    };
  }
}


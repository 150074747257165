
import { Component, Input } from '@angular/core';

/**
 * Interface for InputRadio
 *
 * @property id (required)
 * @property options (required)
 * @property label (optional)
 * @property disabled (optional)
 * @property icon (optional)
 * @property payload (optional)
 * @property classes (optional)
 * @property _meta (optional)
 */
export interface InputRadioData {
  /**
   * A unique ID for the radio item
   */
  id: string;
  /**
   * The array of <option> elements
   */
  options: Array<{
    /**
     * The rendered text for the <option> element
     */
    label: string;
    /**
     * The event value for the <option> element
     */
    value: string | number;
    /**
     * Sets the option as default (selected)
     */
    selected?: boolean;
    /**
     * Prevents the user from selecting this option
     */
    disabled?: boolean;
  }>;
  /**
   * Label for the <select> element
   */
  label?: string;
  /**
   * Prevents the user from changing any option
   */
  disabled?: boolean;
  /**
   * Payload for the select's change event
   */
  payload?: any;
  /**
   * Additional HTML Classes
   */
  classes?: any;
  /**
   * Additional data for the component's logic
   */
  _meta?: any;
}

@Component({
    selector: 'n7-input-radio',
    templateUrl: './input-radio.html'
  })
  export class InputRadioComponent {
    @Input() data: InputRadioData;
  
    @Input() emit: any;
  
    onChange(inputPayload, value?) {
      if (!this.emit) return;
      this.emit('change', { inputPayload, value });
    }
  }
  
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientJsonpModule, HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private token;
    private refreshToken;

    constructor(
        private http: HttpClient
    ) {
    }

    getToken() {
        return this.token;
    }

    setToken(token) {
        this.token = token;
    }

    setRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
    }

    hasToken() {
        return !!this.token;
    }

    apiKey() {
        return environment.api_key;
    }

    useCode(receivedCode) {
        return this.http.post(environment.codeToToken_url, { code: receivedCode });
    }

}

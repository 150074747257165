import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import {
  N7BoilerplateCommonModule,
  N7BoilerplateDataVizModule,
  JsonConfigService
} from '@n7-frontend/boilerplate';
import globalConfig from './config/global';
import layoutsConfig from './config/layouts';
import { APP_ROUTES } from './app.routes';

import { AppComponent } from './app.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout';
import { NotfoundLayoutComponent } from './layout/notfound-layout/notfound-layout';
import { DvComponentsLibModule } from '@n7-frontend/components';
import { Tab1Component } from './tabs/tab1/tab1.component';
import { Tab2Component } from './tabs/tab2/tab2.component';
import { Tab3Component } from './tabs/tab3/tab3.component';
import { Tab4Component } from './tabs/tab4/tab4.component';
import { Tab5Component } from './tabs/tab5/tab5.component';
import { Tab6Component } from './tabs/tab6/tab6.component';
import { TabCoverComponent } from './tabs/tabCover/tabCover.component';
import { DcSectionComponent } from './components/dc-section/dc-section.component';
import { CardsComponent } from './components/cards/cards.component';
import { ItemsComponent } from './components/items/items.component';
import { ChartComponent } from './components/chart/chart.component';
import { ConfigLoaderService } from './services/config-loader.service';
import { InputRadioComponent } from './components/input-radio/input-radio';
import { ModalLayoutComponent } from './layout/modal-layout/modal-layout';

const JSON_PATH = './assets/app-config.json';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    ModalLayoutComponent,
    NotfoundLayoutComponent,
    Tab1Component,
    Tab2Component,
    Tab3Component,
    Tab4Component,
    Tab5Component,
    Tab6Component,
    TabCoverComponent,
    DcSectionComponent,
    CardsComponent,
    ItemsComponent,
    ChartComponent,
    InputRadioComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      APP_ROUTES, {onSameUrlNavigation: 'reload'}
    ),
    N7BoilerplateCommonModule.forRoot({
      global: globalConfig,
      layouts: layoutsConfig
    }),
    N7BoilerplateDataVizModule,
    DvComponentsLibModule
  ],
  providers: [
  {
    provide: APP_INITIALIZER,
    useFactory: (configLoaderService: ConfigLoaderService) => () => configLoaderService.load(JSON_PATH),
    deps: [ConfigLoaderService],
    multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

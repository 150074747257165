import { NotfoundLayoutDS } from './notfound-layout.ds';
import { NotfoundLayoutEH } from './notfound-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const NotfoundLayoutConfig = {
  layoutId: 'dc-notfound-layout',
  widgets: [ ],
  layoutDS: NotfoundLayoutDS,
  layoutEH: NotfoundLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {
    
  }
};

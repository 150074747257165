import { DataSource } from '@n7-frontend/core';
import { IChartData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcChartT5DS extends DataSource {
  protected transform(data, option): IChartData {
    data = data;
    if (!data || !data.market_analysis || !data.market_analysis.economical_data) {
      return;
    }

    let refMarket = [];
    let refCompany = [];
    // let refMarketDiv = null;
    // let refCompanyDiv = null;
    let divisor = 1;
    let graphHolding = false;

    const container = option['key'];
    const measureUnit = option['measureunit'];

    const trend = (values) => {
      if (!values) { return values; }
      const valuesOrdered = Utils.sortByYear(values);
      const res = [];
      const initialValue = valuesOrdered.length > 0 ? valuesOrdered[0].value : 1;
      valuesOrdered.forEach(val => {
        res.push({
          year: val.year,
          // value: (val.value * 100 / initialValue) * (Math.sign(initialValue))
          value: ((val.value - initialValue) / Math.abs(initialValue)) * 100 + 100
        });
      });
      return res;
    };
    
    const hasDivisionIndustriale =  data.company_basic_info.division && data.company_basic_info.division.toLowerCase() === 'industriale';
 
    try {
      switch (container.toLowerCase()) {
        case 'dc-chart-t5a':
          if (data.market_analysis.economical_data.revenues) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.revenues && data.market_analysis.economical_data.revenues.values ? data.market_analysis.economical_data.revenues.values : null,
              hasDivisionIndustriale && data.economical_data.revenues && data.economical_data.revenues.values ? data.economical_data.revenues.values : null, true);
            refMarket = trend(refMarketT);
            refCompany = trend(refCompanyT);
          }
          break;
        case 'dc-chart-t5b':
          if (data.market_analysis.economical_data.ebitda) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.ebitda && data.market_analysis.economical_data.ebitda.values ? data.market_analysis.economical_data.ebitda.values : null,
              hasDivisionIndustriale && data.economical_data.ebitda && data.economical_data.ebitda.values  ? data.economical_data.ebitda.values : null, true);
            refMarket = trend(refMarketT);
            refCompany = trend(refCompanyT);
          }
          graphHolding = true;
          break;
        case 'dc-chart-t5c':
          if (data.market_analysis.economical_data.ebitda_margin) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.ebitda_margin && data.market_analysis.economical_data.ebitda_margin.values ? data.market_analysis.economical_data.ebitda_margin.values : null,
              hasDivisionIndustriale && data.economical_data.ebitda_margin && data.economical_data.ebitda_margin.values ? data.economical_data.ebitda_margin.values : null);
            refMarket = refMarketT;
            refCompany = refCompanyT;
          }
          divisor = 1 / 100;
          graphHolding = true;
          break;
        case 'dc-chart-t5d':
          if (data.market_analysis.economical_data.roe) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.roe && data.market_analysis.economical_data.roe.values ? data.market_analysis.economical_data.roe.values : null,
              hasDivisionIndustriale && data.economical_data.roe && data.economical_data.roe.values ? data.economical_data.roe.values : null);
            refMarket = refMarketT;
            refCompany = refCompanyT;
          }
          divisor = 1 / 100;
          break;
        case 'dc-chart-t5e':
          if (data.market_analysis.economical_data.rapporto_indebitamento) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.rapporto_indebitamento && data.market_analysis.economical_data.rapporto_indebitamento.values ? data.market_analysis.economical_data.rapporto_indebitamento.values : null,
              hasDivisionIndustriale && data.economical_data.rapporto_indebitamento && data.economical_data.rapporto_indebitamento.values ? data.economical_data.rapporto_indebitamento.values : null);
            refMarket = refMarketT;
            refCompany = refCompanyT;
          }
          divisor = 1 / 100;
          break;
        case 'dc-chart-t5f':
          if (data.market_analysis.economical_data.indice_copertura_debiti) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.indice_copertura_debiti && data.market_analysis.economical_data.indice_copertura_debiti.values ? data.market_analysis.economical_data.indice_copertura_debiti.values : null,
              hasDivisionIndustriale && data.economical_data.indice_copertura_debiti && data.economical_data.indice_copertura_debiti.values ? data.economical_data.indice_copertura_debiti.values : null);
            refMarket = refMarketT;
            refCompany = refCompanyT;
          }
          break;
        case 'dc-chart-t5g':
          if (data.market_analysis.economical_data.circolante_operativo_ricavi) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.circolante_operativo_ricavi && data.market_analysis.economical_data.circolante_operativo_ricavi.values ? data.market_analysis.economical_data.circolante_operativo_ricavi.values : null,
              hasDivisionIndustriale && data.economical_data.circolante_operativo_ricavi && data.economical_data.circolante_operativo_ricavi.values ? data.economical_data.circolante_operativo_ricavi.values : null);
            refMarket = refMarketT;
            refCompany = refCompanyT;
          }
          divisor = 1 / 100;
          break;
        case 'dc-chart-t5h':
          if (data.market_analysis.economical_data.indice_rotazione_crediti) {
            const { s1: refMarketT, s2: refCompanyT } = Utils.reduceToCommons(
              data.market_analysis.economical_data.indice_rotazione_crediti && data.market_analysis.economical_data.indice_rotazione_crediti.values ? data.market_analysis.economical_data.indice_rotazione_crediti.values : null,
              hasDivisionIndustriale && data.economical_data.indice_rotazione_crediti && data.economical_data.indice_rotazione_crediti.values ? data.economical_data.indice_rotazione_crediti.values : null);
            refMarket = refMarketT;
            refCompany = refCompanyT;
          }
          break;

      }
    } catch { }

    if ((!refMarket || refMarket.length <= 1) && (!refCompany || refCompany.length <= 1)) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true, holding: graphHolding && data.holding }
      };
    }


    refMarket = Utils.sortByYear(refMarket);


    const values1 = [];
    const values2 = [];
    const categs = [];

    const firstYear = refMarket && refMarket.length ? refMarket[0].year : refCompany[0].year;
    const maxYear = Math.max(refMarket && refMarket.length ? refMarket[refMarket.length - 1].year : firstYear,
      refCompany && refCompany.length ? refCompany[refCompany.length - 1].year : firstYear);

    for (let i = firstYear; i <= maxYear; i++) {
      if (refCompany) {
        const companyVal = refCompany ? refCompany.find(x => x.year === i) : null;
        values2.push(companyVal ? ((companyVal.value) / divisor) : null);
      }
      const marketVal = refMarket ? refMarket.find(x => x.year === i) : null;
      values1.push(marketVal ? (marketVal.value) / divisor : null);
      categs.push(i);
    }

    const valueSeries = [];
    if (hasDivisionIndustriale) {
      valueSeries.push({
        name: 'Società',
        data: values2
      });
    }

    valueSeries.push({
      name: 'Settore Ateco',
      data: values1
    });

    const chartOption = {
      series: valueSeries,
      colors: Utils.palette,
      chart: {
        id: container,
        fontFamily: 'Open Sans',
        animations: {
          enabled: data.noAnimation ? false : true
        },
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      legend:{
        show: true,
        showForSingleSeries: true
      },
      markers: {
        size: [4],
        strokeWidth: 2,
        hover: {
          size: [6]
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.71,
          opacityTo: 0.01,
        }
      },
      xaxis: {
        categories: categs,
        labels: {
          // show: false
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: (min) => min - (Math.abs(min) / Utils.minMaxAxis),
        max: (max) => max + (Math.abs(max) / Utils.minMaxAxis),
        tickAmount: 5,
        labels: {
          // show: false
          formatter(value) {
            return Utils.approx(value);
          }
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return Utils.approx(value) + (measureUnit ? measureUnit : '');
          }
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: container,
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}

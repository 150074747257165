
import { Routes } from '@angular/router';
import {
  Page404LayoutComponent
} from '@n7-frontend/boilerplate';
import { HomeLayoutComponent } from './layout/home-layout/home-layout';
import { NotfoundLayoutComponent } from './layout/notfound-layout/notfound-layout';

export const APP_ROUTES: Routes = [
  // dossier-commerciale routes
  { path: ':nav/:name/:id/:token', redirectTo: ':nav/:name/:id'},
  { path: ':nav/:name/:id', component: HomeLayoutComponent },
  { path: 'sso_return', component: HomeLayoutComponent },
  { path: 'error', component: NotfoundLayoutComponent },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  // page404
  { path: '**', component: NotfoundLayoutComponent }
];

import { AbstractLayout, CommunicationService, ConfigurationService } from '@n7-frontend/boilerplate';
import { OnInit, OnDestroy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ModalLayoutConfig as config } from './modal-layout.config';
import { Location } from '@angular/common';
import { ComponentMediatorService } from 'src/app/services/component-mediator.service';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dc-modal-layout',
    templateUrl: './modal-layout.html'
})
export class ModalLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {

    public type;

    constructor(
        private communication: CommunicationService,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private configService: ConfigurationService,
        private location: Location,
        private auth: AuthService,
        private mediator: ComponentMediatorService
    ) {
        super(config);
    }

    protected initPayload() {
        return {
            communication: this.communication,
            router: this.activatedRouter,
            navRouter: this.router,
            auth: this.auth,
            mediator: this.mediator
        };
    }
    ngOnInit(): void {
        this.mediator.register('dc-modal-layout');
        this.onInit();
        this.lb.widgets['dc-modal'].ds.out$.subscribe(data => {
            if (!data) {
                this.type = null;
                return;
            }
            this.type = data.type;
        })
    }

    ngOnDestroy(): void {
        this.mediator.remove('dc-modal-layout');
        this.onDestroy();
    }

    submit(payload): void {
        this.lb.eventHandler.emitInner('submit', {type: this.type, payload: payload});
    }

    close(): void {
        this.lb.eventHandler.emitInner('close', this.type);
    }

}

import { DataSource } from '@n7-frontend/core';
import { INavData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcNavDS extends DataSource {
  protected transform(data): INavData {
    const id = data.cId;
    const name = data.cName;
    const nav = data.nav;
    const itemsList = [];

    const hasDivision = !!data.navData.company_basic_info.division;
    const hasSectorAnalysis = data.navData.company_basic_info.sector_analysis;
    const hasIsotype = !!data.navData.company_basic_info.isotype_name && data.navData.company_basic_info.isotype_name.toLowerCase() !== 'altri';
    const hasPrimaryNeeds = !!data.navData.company_basic_info.isotype_main_needs && data.navData.company_basic_info.isotype_main_needs.length; 
    const showLineeGuidaComm = !!data.showLineeGuidaComm && hasPrimaryNeeds;
    const hasKpi = Utils.hasKpiData(data.navData.market_analysis.economical_data);
    const customKpi = typeof data.navData.company_basic_info.sector_name === "string" && data.navData.company_basic_info.sector_name === "";
    itemsList.push({
      text: 'Presentazione Azienda',
      anchor: { payload: `/home/${name}/${id}` },
      classes: nav === 'home' ? 'is-active' : ''
    });
    if (hasDivision) {
      itemsList.push({
        text: 'Principali dati di bilancio',
        anchor: { payload: `/balance/${name}/${id}` },
        classes: nav === 'balance' ? 'is-active' : ''
      });
    }
    if (hasSectorAnalysis) {
      itemsList.push({
        text: 'Analisi del settore',
        anchor: { payload: `/analysis/${name}/${id}` },
        classes: nav === 'analysis' ? 'is-active' : ''
      });
    }
    if (hasSectorAnalysis) {
      itemsList.push({
        text: 'Opportunità e minacce',
        anchor: { payload: `/manace/${name}/${id}` },
        classes: nav === 'manace' ? 'is-active' : ''
      });
    }
    if ((hasSectorAnalysis && hasKpi) || customKpi) {
      itemsList.push({
        text: 'KPI di bilancio e benchmark',
        anchor: { payload: `/kpi/${name}/${id}` },
        classes: nav === 'kpi' ? 'is-active' : ''
      });
    }
    if (hasIsotype && showLineeGuidaComm) {
      itemsList.push({
        text: 'Linee Guida commerciali',
        anchor: { payload: `/lines/${name}/${id}` },
        classes: nav === 'lines' ? 'is-active' : ''
      });
    }

    return {
      items: itemsList
    };
  }
}

import { DataSource } from '@n7-frontend/core';
import { IInnerTitleData } from '@n7-frontend/components';
export class DcItitleT1DS extends DataSource {
  protected transform(data, option): IInnerTitleData {
    if (!data) {
      return;
    }

    const mainText = option['key'];
    let secondaryText = '';

    switch (mainText.toLowerCase()) {
      case 'ricavi':
        const lastupRev = data.economical_data.revenues.last_update;
        secondaryText = lastupRev ? 'Ricavi al ' + lastupRev : '';
        break;
      case 'dipendenti':
        const lastupEmpl = data.company_basic_info.employees.last_update;
        secondaryText = lastupEmpl ? 'Dipendenti al ' + lastupEmpl : '';
        break;
        case 'età':
          //const birthday = data.company_basic_info.people.items[0].birthdate;
          const birthday = data.company_basic_info.general_data.find(x => x.label === 'Sul mercato da').value;
          secondaryText = birthday ? 'Dal ' + birthday : '';
          break;
    }
    return {
      title: {
        main: {
          text: mainText
        },
        secondary: {
          text: secondaryText
        }
      }
    };
  }
}


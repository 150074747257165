import { Injectable } from '@angular/core';
import ApexCharts from 'apexcharts/dist/apexcharts.common.js';

@Injectable({ providedIn: 'root' })
export class ChartService {

    waitingForPrint = -1;

    constructor(
    ) {
    }

    public waitTime = 200;

    convertToImage(chartsId, images) {
        const ps = [];
        chartsId.forEach(cId => {
            try {
                const p = ApexCharts.exec(cId, 'dataURI', 'image/png').then(res => {
                    images[cId] = res.imgURI;
                    return;
                });
                ps.push(p);
            } catch (e) { }
        });
        return Promise.all(ps);
    }

    notReadyForPrint() {
        if (this.waitingForPrint < 0) {
            this.waitingForPrint = 0;
        }
        this.waitingForPrint++;
    }
    readyForPrint() {
        this.waitingForPrint--;
    }

    isReadyForPrint() {
        return this.waitingForPrint === 0;
    }
}

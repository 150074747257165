import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dc-section',
  templateUrl: './dc-section.component.html',
  styleUrls: ['./dc-section.component.scss']
})
export class DcSectionComponent implements OnInit {

  @Input() title: string;
  @Input() label: string;
  @Input() value: string;
  
  constructor() { }

  ngOnInit() {
  }

}

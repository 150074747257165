import { LayoutDataSource } from '@n7-frontend/core';

export class ModalLayoutDS extends LayoutDataSource {

  onInit(payload) {
  }

  showRadio(data: any): void {
    this.one('dc-modal').update({type:'radio', payload: {
      text: 'Questa azienda corrisponde a più settori, seleziona il settore di riferimento',
      actions: [{
        label: 'Prosegui',
        payload: true
      }]
    }});
    this.one('dc-title-modal').update(data.title);
    this.one('dc-radio-modal').update(data);
  }

  hideModal(): void {
    this.one('dc-modal').update(null);
    this.one('dc-radio-modal').update(null);
  }

  showConfirm(data: any): void {
    this.one('dc-modal').update({type:'confirm', payload: data});
    this.one('dc-title-modal').update(data.title);
    this.one('dc-radio-modal').update(null);
  }

}

import { DataSource } from '@n7-frontend/core';
import { IInnerTitleData } from '@n7-frontend/components';
export class DcTitleModalDS extends DataSource {

    protected transform(payload, option): IInnerTitleData {
        if (!payload) {
            return;
        }

        return {
            image: payload.logo,
            title: {
                main: {
                    text: payload.text,
                }
            }
        };
    }

}

import { DataSource } from '@n7-frontend/core';
import { IMetadataViewerData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcMetadataT1aDS extends DataSource {
  protected transform(data): IMetadataViewerData {
    if (!data) {
      return;
    }

    data.general_data.forEach(element => {
      if (element.label.toLowerCase() === 'capitale sociale' && element.value) {
        element.value = Utils.bigNumberPoints(element.value) + ' €';
      }
      if (element.label.toLowerCase() === 'natura giuridica' && element.value) {
        element.value = (element.value + '').split(',').join(', ');
      }
      if (!element.value) {
        element.value = '<span class="info-not-available">info non disponibile</span>';
      }
    });

    return {
      classes: 'example-class',
      group: [{
        items: data.general_data
      }]
    };
  }
}

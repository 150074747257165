import { DataSource } from '@n7-frontend/core';
import { IChartData } from '@n7-frontend/components';
import { Utils } from './utils';

export class DcChartT3dDS extends DataSource {

  constructor() { super(); }

  protected transform(data): IChartData {
    if (!data) {
      return;
    }



    const values = [];
    const categs = [];

    // Utils.sortByYear(data.export_trend.values).forEach(x => {
    //   values.push(x.value);
    //   categs.push(x.label);
    // });

    if (!data.export || !data.export.values || Utils.HasNoValue(data.export.values)) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }

    values.push(data.export.last_value);
    categs.push('Export');

    values.push(data.internal_market.last_value);
    categs.push('Mercato Italia');

    const tot = (data.export.last_value + data.internal_market.last_value);
    const percentage = [(data.export.last_value * 100 / (tot ? tot : 1)), (data.internal_market.last_value * 100 / (tot ? tot : 1))]

    const chartOption = {
      chart: {
        id: 'dc-chart-t3d',
        fontFamily: 'Open Sans',
        animations: {
          enabled: data.noAnimation ? false : true
        },
        type: 'pie',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#00A7F8', '#98D1B0'],
      series: values,
      labels: categs,
      legend: {
        markers: {
          width: 10,
          height: 10,
          radius: 10,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const html = Utils.getTooltip(
            categs[seriesIndex],
            Math.round(percentage[seriesIndex]),
            null,
            '(' + values[seriesIndex] + ' Mln€)'
          );
          return html;
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: 'dc-chart-t3d',
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}

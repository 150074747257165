import { DataSource } from '@n7-frontend/core';
import { IChartData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcChartT1cDS extends DataSource {
  MAX_VALUES = 3;

  protected transform(data): IChartData {
    if (!data || !data.participations) {
      return;
    }
    const values = [];
    const categs = [];
    let othersNum = 0;
    let othersTotal = 0;
    data.participations.values.sort((a, b) => a.percentage > b.percentage ? -1 : 1).forEach((x, idx) => {
      if (idx < this.MAX_VALUES) {
        values.push(x.percentage);
        categs.push(x.label);
      } else {
        othersNum++;
        othersTotal += x.value ? x.value : 0;
      }
    });

    if (!values || values.length === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true, partecipazioni : true }
      };
    }

    const chartOption = {
      chart: {
        id: 'dc-chart-t1c',
        fontFamily: 'Open Sans',
        animations: {
          enabled: data.noAnimation ? false : true
        },
        type: 'bar',
        height: '260px',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 5,
          distributed: true,
          barHeight: '50%',
        }
      },
      colors: Utils.palette2,
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const html = Utils.getTooltip(
            data.participations.values[dataPointIndex].label,
            data.participations.values[dataPointIndex].percentage,
            Utils.bigNumberConverter(data.participations.values[dataPointIndex].value),
            'partecipazioni'
          );
          return html;
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [{
        data: values
      }],
      xaxis: {
        categories: categs,
        labels: {
          formatter: function (value) {
            return value + '%';
          }
        },
        axisTicks: {
          show: false
        },
        max: 100,
        tickAmount: 4
      },
      yaxis: {
        min: (min) => min - (Math.abs(min) / Utils.minMaxAxis),
        max: (max) => max + (Math.abs(max) / Utils.minMaxAxis),
        tickAmount: 5,
        axisBorder: {
          show: false
        }
      },
      legend: {
        horizontalAlign: 'left',
        markers: {
          width: 10,
          height: 10,
          radius: 10,
        },
      },
      grid: {
        show: false,
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: 'dc-chart-t1c',
      libOptions: chartOption,
      payload: { otherNum: othersNum, otherTotal: Utils.bigNumberConverter(othersTotal), loaded: true }
    };
  }
}

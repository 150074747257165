import { DataSource } from '@n7-frontend/core';
import { IChartData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcChartT3DS extends DataSource {
  protected transform(data, option): IChartData {
    data = data;
    if (!data || !data.market_analysis || !data.market_analysis.economical_data) {
      return;
    }

    let ref = [];
    let color = '#0FA5EB';
    const container = option['key'];
    let divisor = 1;
    let label = '';

    switch (container.toLowerCase()) {
      case 'dc-chart-t3b':
        if (data.market_analysis.economical_data.production) {
          ref = data.market_analysis.economical_data.production.values;
        }
        color = Utils.palette[0];
        label = 'Produzione';
        break;
      case 'dc-chart-t3c':
        if (data.market_analysis.economical_data.import) {
          ref = data.market_analysis.economical_data.import.values;
        }
        color = Utils.palette[1];
        label = 'Import';
        break;
      case 'dc-chart-t3e':
        if (data.market_analysis.economical_data.export) {
          ref = data.market_analysis.economical_data.export.values;
        }
        color = Utils.palette[2];
        label = 'Export';
        break;
      case 'dc-chart-t3f':
        if (data.market_analysis.economical_data.internal_market) {
          ref = data.market_analysis.economical_data.internal_market.values;
        }
        color = Utils.palette[3];
        label = 'Mercato interno';
        break;

    }

    if (!ref || ref.length === 0 || Utils.HasNoValue(ref)) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }

    ref = Utils.sortByYear(ref);


    const values = [];
    const categs = [];
    ref.forEach(x => {
      values.push(x.value);
      categs.push(x.year);
    });
    const dashedValues = JSON.parse(JSON.stringify(values));
    const normalValues = JSON.parse(JSON.stringify(values));
    normalValues.splice(normalValues.length - 2);
    const chartOption = {
      series: [{
        name: label,
        data: normalValues
      }, {
        name: label,
        data: dashedValues
      }],
      colors: [color],
      chart: {
        id: container,
        fontFamily: 'Open Sans',
        animations: {
          enabled: data.noAnimation ? false : true
        },
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      stroke: {
        width: 3,
        curve: 'straight',
        dashArray: [0, 4]
      },
      markers: {
        size: [4],
        strokeWidth: 2,
        hover: {
          size: [6]
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 0,
        type: ['solid', 'gradient'],
        gradient: {
          opacityFrom: 0.61,
          opacityTo: 0.01,
        }
      },
      xaxis: {
        categories: categs,
        labels: {
          // show: false
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: (min) => min - (Math.abs(min) / Utils.minMaxAxis),
        max: (max) => max + (Math.abs(max) / Utils.minMaxAxis),
        tickAmount: 5,
        labels: {
          // show: false
          formatter: function (value) {
            return Math.round(value / divisor);
          }
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        }
      },
      legend: {
        show: false
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: container,
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}

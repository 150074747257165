import { DataSource } from '@n7-frontend/core';
import { IChartData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcChartT1aDS extends DataSource {
  protected transform(data): IChartData {
    if (!data || !data.employees) {
      return;
    }
    const values = [];
    const categs = [];
    Utils.sortByYear(data.employees.values).forEach(x => {
      values.push(x.value);
      categs.push(x.year);
    });

    
    if (!values || values.length === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true }
      };
    }

    const chartOption = {
      series: [{
        name: 'Dipendenti',
        data: values
      }],
      colors: ['#0FA5EB'],
      chart: {
        id: 'dc-chart-t1a',
        fontFamily: 'Open Sans',
        height: 100,
        animations: {
          enabled: data.noAnimation ? false : true
        },
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      markers: {
        size: [4],
        strokeWidth: 2,
        hover: {
          size: [6]
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.71,
          opacityTo: 0.01,
        }
      },
      xaxis: {
        categories: categs,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: (min) => min - (Math.abs(min) / Utils.minMaxAxis),
        max: (max) => max + (Math.abs(max) / Utils.minMaxAxis),
        tickAmount: 5,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: 'dc-chart-t1a',
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}

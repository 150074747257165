import { EventHandler } from '@n7-frontend/core';

export class NotfoundLayoutEH extends EventHandler {

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'dc-notfound-layout.init':
          this.dataSource.onInit(payload);
      }
    });

  }
}

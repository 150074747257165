import { DataSource } from '@n7-frontend/core';
import { IInnerTitleData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcItitleT3DS extends DataSource {
  protected transform(data, option): IInnerTitleData {
    if (!data || !data.market_analysis) {
      return;
    }

    let ref: any;

    switch (option['key'].toLowerCase()) {
      case 'dc-ititle-t3a': {
        ref = data.market_analysis.economical_data.revenues_italy;
      }
        break;
      case 'dc-ititle-t3b': {
        ref = data.market_analysis.economical_data.production;
      }
        break;
      case 'dc-ititle-t3c': {
        ref = data.market_analysis.economical_data.import;
      }
        break;
      case 'dc-ititle-t3d': {
        ref = data.market_analysis.economical_data.export_trend;
      }
        break;
      case 'dc-ititle-t3e': {
        ref = data.market_analysis.economical_data.export;
      }
        break;
      case 'dc-ititle-t3f': {
        ref = data.market_analysis.economical_data.internal_market;
      }
        break;
    }

    let label = '';
    label = option['label'];

    let sublabel = '';

    if (ref && ref.values && !Utils.HasNoValue(ref.values)) {
      ref.values = Utils.sortByYear(ref.values);

      const start = ref.values.length > 0 ? ref.values[0].year : '-';
      const end = ref.values.length > 0 ? ref.values[ref.values.length - 1].year : '-';

      label = option['label'];
      sublabel = option['sublabel'];

      label = label.replace('${year}', end);

      if (sublabel.indexOf('${last_update}') > 0 && !ref.last_update) {
        sublabel = '';
      } else {
        sublabel = sublabel.replace('${last_update}', ref.last_update);
      }

      if (sublabel.indexOf('${start_year}') > 0 && !start) {
        sublabel = '';
      } else {
        sublabel = sublabel.replace('${start_year}', start);
      }

      if (sublabel.indexOf('${end_year}') > 0 && !end) {
        sublabel = '';
      } else {
        sublabel = sublabel.replace('${end_year}', end);
      }
    } else {
      label = label.replace('${year}', '');
    }

    return {
      title: {
        main: {
          text: label
        },
        secondary: {
          text: sublabel
        }
      }
    };
  }
}


import { DataSource } from '@n7-frontend/core';
import { ITableData } from '@n7-frontend/components';
export class DcTableT1aDS extends DataSource {
  protected transform(data): ITableData {
    if (!data || !data.locations || !data.locations.values) {
      return;
    }

    const table = [];

    const renameLocationLabel = (loc: string) => {
      if (loc) {
        switch (loc.toLowerCase()) {
          case 'mainaddress': return 'Sede legale';
        }
      } else {
        // loc = 'altro';
      }
      return loc;
    }

    data.locations.values.forEach(loc => {
      table.push({
        classes: 'BODY',
        cells: [
          {
            classes: 'CONTENT',
            content: renameLocationLabel(loc.label),
            type: 'html'
          },
          {
            classes: 'CONTENT',
            content: loc.address,
            type: 'html'
          }
        ]
      });
    });

    return {
      classes: 'test-layout-table',
      body: table
    };
  }
}

import { Component, OnInit, Output, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'app-tab6',
    templateUrl: './tab6.component.html'
})
export class Tab6Component implements OnInit {

    @Input() lb: any;
    @Input() print: boolean;

    public images = [];
    public data;

    public isotype_main_needs = [];
    public isotype_secondary_needs = [];

    constructor(
        private chartService: ChartService) { }

    ngOnInit() {
        this.lb.widgets['dc-home'].ds.out$.subscribe(x => {
            this.data = x;
            if (x) {
                this.isotype_main_needs = x.company_basic_info.isotype_main_needs;
                this.isotype_secondary_needs = x.company_basic_info.isotype_secondary_needs;
            }
        });
    }

    public getImage() {
        let imageUrl = '/assets/images/isotopes/';
        switch (this.data.company_basic_info.isotype_name.toLowerCase()) {
            case 'Altri Liberi Professionisti'.toLowerCase():
                imageUrl += 'altri-liberi-professionisti.jpg';
                break;
            case 'Commercianti all\'ingrosso'.toLowerCase():
                imageUrl += 'commercianti-ingrosso.jpg';
                break;
            case 'Costruttori'.toLowerCase():
                imageUrl += 'costruttori.jpg';
                break;
            case 'Grande Distribuzione'.toLowerCase():
                imageUrl += 'grande-distribuzione.jpg';
                break;
            case 'Liberi professionisti'.toLowerCase():
            case 'Equipment specializzato'.toLowerCase():
            case 'Liberi professionisti - Equipment specializzato'.toLowerCase():
            case 'Liberi Professionisti Con Equipment Specializzato'.toLowerCase():
                imageUrl += 'liberi-professionisti.jpg';
                break;
            case 'Merchant non Specializzati'.toLowerCase():
                imageUrl += 'merchant-non-specializzati.jpg';
                break;
            case 'Merchant Specializzati'.toLowerCase():
                imageUrl += 'merchant-specializzati.jpg';
                break;
            case 'Piccoli Artigiani'.toLowerCase():
                imageUrl += 'piccoli-artigiani.jpg';
                break;
            case 'PMI Manufatturiere'.toLowerCase():
                imageUrl += 'pmi-manufatturiere.jpg';
                break;
            case 'Servizi di Consulenza'.toLowerCase():
                imageUrl += 'servizi-consulenza.jpg';
                break;
            case 'Servizi Finanziari'.toLowerCase():
                imageUrl += 'servizi-finanziari.jpg';
                break;
            case 'Servizi alle imprese'.toLowerCase():
                imageUrl += 'servizi-imprese.jpg';
                break;
            case 'Sviluppo Immobiliare'.toLowerCase():
                imageUrl += 'sviluppo-immobiliare.jpg';
                break;
            case 'Trasporti'.toLowerCase():
                imageUrl += 'trasporti.jpg';
                break;
            case 'Turismo'.toLowerCase():
                imageUrl += 'turismo.jpg';
                break;
            case 'Agricoltori'.toLowerCase():
                imageUrl += 'agricoltori.jpg';
                break;
            case 'Bar e Ristoranti'.toLowerCase():
                imageUrl += 'bar-ristoranti.jpg';
                break;
            default:
                imageUrl += 'default.jpg';

        }
        return `url(\'${imageUrl}\')`;
    }







}

import { DataSource } from '@n7-frontend/core';
import { ITableData } from '@n7-frontend/components';
import { AttachSession } from 'protractor/built/driverProviders';
import { Utils } from './utils';
export class DcTableT1bDS extends DataSource {
  protected transform(data): ITableData {
    if (!data || !data.people || !data.people.items) {
      return;
    }

    const table = [];

    const formatDate = (date) => {
      return date;
      // return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    

    const getRoles = (roles) => {
      let res = '<ul>';
      roles.forEach(role => {
        res += `<li><strong>${role.label}</strong> ` + (role.date_from ? `dal ${formatDate(role.date_from)}</li>` : '');
      });

      return res + '</ul>';
    };

    data.people.items.forEach(person => {
      table.push({
        classes: 'BODY',
        cells: [
          {
            classes: 'CONTENT',
            content: `${person.name} ${person.last_name}`,
            type: 'html'
          },
          // {
          //   classes: 'CONTENT',
          //   content: person.address,  // TODO campo corretto della città della persona
          //   type: 'html'
          // },
          {
            classes: 'CONTENT',
            content: person.birthdate ? `${Utils.getAge(person.birthdate)} anni (${formatDate(person.birthdate)})` : '-',
            type: 'html'
          },
          {
            classes: 'CONTENT',
            content: getRoles(person.roles),
            type: 'html'
          }
        ]
      });
    });

    return {
      classes: 'test-layout-table',
      body: table
    };

  }
}

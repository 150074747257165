import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';
import { ModalLayoutDS } from './modal-layout.ds';
import { ModalLayoutEH } from './modal-layout.eh';
import { DcRadioModalDS } from 'src/app/data-sources/dc-radio-modal.ds';
import { DcRadioModalEH } from 'src/app/event-handlers/dc-radio-modal.eh';
import { DataSource } from '@n7-frontend/core';
import { DcHomeDS } from '../../data-sources';
import { DcTitleModalDS } from 'src/app/data-sources/dc-title-modal.ds';

export const ModalLayoutConfig = {
  layoutId: 'dc-modal-layout',
  widgets: [
    {
      id: 'dc-title-modal',
      dataSource: DcTitleModalDS
    },
    {
      id: 'dc-radio-modal',
      dataSource: DcRadioModalDS,
      eventHandler: DcRadioModalEH
    },
    {
      id: 'dc-modal',
      dataSource: DcHomeDS
    }
  ],
  layoutDS: ModalLayoutDS,
  layoutEH: ModalLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {
  }
};

import { Component, OnInit, Output, Input } from '@angular/core';

@Component({
    selector: 'app-tab-cover',
    templateUrl: './tabCover.component.html'
})
export class TabCoverComponent implements OnInit {

    @Input() lb: any;
    @Input() image: any;
    public img = '';
    public name = '';

    constructor() { }

    ngOnInit() {
    }

}

import { DataSource } from '@n7-frontend/core';
import { IChartData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcChartT2DS extends DataSource {
  protected transform(data, option): IChartData {
    data = data;
    if (!data || !data.economical_data) {
      return;
    }

    let ref = [];
    let color = '#0FA5EB';
    const container = option['key'];
    let divisorLabel = 1;
    let moltiplicator = 1;
    let label = '';
    let graphHolding = false;

    switch (container.toLowerCase()) {
      case 'dc-chart-t2a':
        ref = data.economical_data.revenues.values;
        color = Utils.palette[0];
        label = 'Ricavi';
        break;
      case 'dc-chart-t2b':
        ref = data.economical_data.ebitda.values;
        color = Utils.palette[1];
        label = 'EBITDA';
        graphHolding = true;
        break;
      case 'dc-chart-t2c':
        ref = data.economical_data.profit.values;
        color = Utils.palette[2];
        label = 'Utile';
        break;
      case 'dc-chart-t2d':
        ref = data.economical_data.posizione_finanziaria_netta.values;
        color = Utils.palette[3];
        label = 'Posizione finanziaria netta';
        break;
      case 'dc-chart-t2e':
        ref = data.economical_data.patrimonio_netto.values;
        color = Utils.palette[4];
        label = 'Patrimonio netto';
        break;
      case 'dc-chart-t2f':
        ref = data.economical_data.cash_flow.values;
        color = Utils.palette[5];
        label = 'Cash flow';
        break;
      case 'dc-chart-t2g':
        ref = data.economical_data.ebitda_percentage.values;
        color = Utils.palette[6];
        // divisorLabel = 1000;
        moltiplicator = 100;
        label = 'EBITDA %';
        graphHolding = true;
        break;
      case 'dc-chart-t2h':
        ref = data.economical_data.roe_percentage.values;
        color = Utils.palette[7];
        moltiplicator = 100;
        label = 'ROE %';
        break;
      case 'dc-chart-t2i':
        ref = data.economical_data.profit_percentage.values;
        color = Utils.palette[8];
        moltiplicator = 100;
        label = 'Utile %';
        break;
      case 'dc-chart-t2j':
        ref = data.economical_data.patrimonio_netto_totale_attivo.values;
        color = Utils.palette[9];
        moltiplicator = 100;
        label = 'Patrimonio netto/Totale attivo';
        break;
      case 'dc-chart-t2k':
        ref = data.economical_data.capitale_circolante_netto_operativo.values;
        color = Utils.palette[10];
        label = 'Capitale circolante operativo';
        graphHolding = true;
        break;
      case 'dc-chart-t2l':
        if (data.economical_data.posizione_finanziaria_netta_ebitda && data.economical_data.posizione_finanziaria_netta_ebitda.values) {
          ref = data.economical_data.posizione_finanziaria_netta_ebitda.values;
        }
        color = Utils.palette[11];
        label = 'Posizione finanziaria netta / EBITDA';
        graphHolding = true;
        break;
    }

    if (!ref || ref.length === 0) {
      return {
        containerId: null,
        libOptions: null,
        payload: { loaded: true, holding: graphHolding && data.holding }
      };
    }

    divisorLabel = Utils.getBigNumberDivider(ref, true);
    // const eurolabel = Utils.getSizeLabelForData(ref);

    ref = Utils.sortByYear(ref);


    const values = [];
    const categs = [];
    ref.forEach(x => {
      const val = (x.value * moltiplicator) / divisorLabel;
      values.push(Math.abs(val) > 5 ? Math.round(val) : (Math.round(val * 100) / 100));
      categs.push(x.year);
    });
    const chartOption = {
      series: [{
        name: label,
        data: values
      }],
      colors: [color],
      chart: {
        id: container,
        fontFamily: 'Open Sans',
        animations: {
          enabled: data.noAnimation ? false : true
        },
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      markers: {
        size: [4],
        strokeWidth: 2,
        hover: {
          size: [6]
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.71,
          opacityTo: 0.01,
        }
      },
      xaxis: {
        categories: categs,
        labels: {
          // show: false
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        min: (min) => min - (Math.abs(min) / Utils.minMaxAxis),
        max: (max) => max + (Math.abs(max) / Utils.minMaxAxis),
        tickAmount: 5,

        // labels: {
        //   // show: false
        //   formatter: function (value) {
        //     return Math.round(value / divisorLabel);
        //   }
        // },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      noData: {
        text: 'Dato non disponibile',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    };

    return {
      containerId: container,
      libOptions: chartOption,
      payload: { loaded: true }
    };

  }
}

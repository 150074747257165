import { Component, OnInit, Output, Input } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';

@Component({
    selector: 'app-tab4',
    templateUrl: './tab4.component.html'
})
export class Tab4Component implements OnInit {
    @Input() lb: any;
    @Input() print: boolean;
    public data : any = {};
    
    // public images = [];

    public sectorName = '';
    public menace = '';
    public opportunities = '';
    constructor(
        private chartService: ChartService) { }

    ngOnInit() {
        this.lb.widgets['dc-home'].ds.out$.subscribe(x => {
            this.data = x;
            if (x) {
                this.sectorName = x.company_basic_info.sector_name;
                this.menace = x.menace_opportunities.menace;
                this.opportunities = x.menace_opportunities.opportunities;
            }
        });
    }

}

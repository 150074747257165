import { EventHandler } from '@n7-frontend/core';
export class DcRadioModalEH extends EventHandler {
    public listen() {
        this.innerEvents$.subscribe(({ type, payload }) => {
            switch (type) {
                case 'dc-radio-modal.change':
                    this.emitOuter('change', payload);
                    break;
            }
        })
    }
}

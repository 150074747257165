import { LayoutDataSource } from '@n7-frontend/core';
import { HttpHeaders } from '@angular/common/http';

export class NotfoundLayoutDS extends LayoutDataSource {
  private communication;
  private router;


  onInit(payload) {
    this.communication = payload.communication;
    this.router = payload.router;
  }
}

import { EventHandler } from '@n7-frontend/core';
export class DcNavEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      if (type === 'dc-nav.click') {
         this.emitGlobal('navigate', { handler: 'router', path: [payload] });
      }
    });
  }
}

import { DataSource } from '@n7-frontend/core';
import { IInnerTitleData } from '@n7-frontend/components';
import { Utils } from './utils';
export class DcItitleT5DS extends DataSource {
  protected transform(data, option): IInnerTitleData {
    if (!data || !data.market_analysis) {
      return;
    }

    let ref: any;
    let ref_bis: any;
    let reduceWithNoZero = false;

    switch (option['key'].toLowerCase()) {
      case 'dc-ititle-t5a': {
        ref = data.market_analysis.economical_data.revenues;
        ref_bis = data.economical_data.revenues;
        reduceWithNoZero = true;
      }
        break;
      case 'dc-ititle-t5b': {
        ref = data.market_analysis.economical_data.ebitda;
        ref_bis = data.economical_data.ebitda;
        reduceWithNoZero = true;
      }
        break;
      case 'dc-ititle-t5c': {
        ref = data.market_analysis.economical_data.ebitda_margin;
      }
        break;
      case 'dc-ititle-t5d': {
        ref = data.market_analysis.economical_data.roe;
      }
        break;
      case 'dc-ititle-t5e': {
        ref = data.market_analysis.economical_data.rapporto_indebitamento;
      }
        break;
      case 'dc-ititle-t5f': {
        ref = data.market_analysis.economical_data.indice_copertura_debiti;
      }
        break;
      case 'dc-ititle-t5g': {
        ref = data.market_analysis.economical_data.circolante_operativo_ricavi;
      }
        break;
      case 'dc-ititle-t5h': {
        ref = data.market_analysis.economical_data.indice_rotazione_crediti;
      }
        break;
    }

    let label = '';
    label = option['label'];

    let sublabel = '';


    if (ref) {
      const start = ref.values.length > 0 ? Utils.sortByYear(ref.values)[0].year : '-';
      // const end = ref.values.length > 0 ? ref.values[ref.values.length - 1].year : '-';

      sublabel = option['sublabel'];

      let commonstart = '';
      if (ref_bis) {
        const commSerie = Utils.reduceToCommons(ref.values, ref_bis.values, reduceWithNoZero);
        if (commSerie && commSerie.s1 && commSerie.s1.length) {
          commonstart = commSerie.s1[0].year;
        }
      }

      // label = label.replace('${year}', end);

      // sublabel = sublabel.replace('${last_update}', ref.last_update);
      if (sublabel.indexOf('${start_year}') > 0 && !start) {
        sublabel = '';
      } else {
        sublabel = sublabel.replace('${start_year}', start);
      }
      if (sublabel.indexOf('${start_common_year}') > 0 && !commonstart) {
        sublabel = '';
      } else {
        sublabel = sublabel.replace('${start_common_year}', commonstart);
      }
      // sublabel = sublabel.replace('${end_year}', end);
    }
    return {
      title: {
        main: {
          text: label
        },
        secondary: {
          text: sublabel
        }
      }
    };
  }
}


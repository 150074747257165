import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractLayout, CommunicationService } from '@n7-frontend/boilerplate';
import { NotfoundLayoutConfig as config } from './notfound-layout.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'dc-notfound-layout',
  templateUrl: './notfound-layout.html'
})
export class NotfoundLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {

  public error = false;

  constructor(
    private communication: CommunicationService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private location: Location,
    private auth: AuthService
  ) {
    super(config);
  }

  protected initPayload() {
    return {
      communication: this.communication,
      router: this.activatedRouter,
      auth: this.auth
    };
  }

  ngOnInit() {
    if (this.router.url === '/error') { this.error = true; }
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ComponentMediatorService {

    private components = {};

    constructor() { }

    register(id: string): Observable<IMediatorMessage> {
        if (!id) {
            throw new Error('Invalid component id');
        }
        if (this.components[id]) {
            return this.components[id];
        }
        const subject: BehaviorSubject<IMediatorMessage> = new BehaviorSubject<IMediatorMessage>({type: 'init', payload: null});
        subject.asObservable();
        this.components[id] = subject;
        return subject;
    };

    remove(id: string): void {
        if (!id || !this.components[id]) {
            return;
        }
        const subject: BehaviorSubject<IMediatorMessage> = this.components[id];
        subject.complete();
        this.components[id] = undefined;
    };

    sendMessage(target: string, type: String, payload: any): void {
        if(!target || !this.components[target]){
            return;
        }
        const subject: BehaviorSubject<IMediatorMessage> = this.components[target];
        const message: IMediatorMessage = {type: type, payload: payload};
        subject.next(message); 
    }
}

export interface IMediatorMessage {
    type: String,
    payload: any
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationService, JsonConfigService } from '@n7-frontend/boilerplate';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ConfigLoaderService {

  constructor(public http: HttpClient,
    private config: ConfigurationService,
    private auth: AuthService,
    private angularLocation: Location,
    private jsonConfigService: JsonConfigService
  ) { }

  public async load(JSON_PATH) {
    await this.jsonConfigService.load(JSON_PATH);

    const parsedURL = this.parseURL();
    if (parsedURL.error) {
      this.onError(parsedURL.error);
      return;
    }

    if(parsedURL.sector){
      this.config.set('sector',parsedURL.sector);
    }

    if (parsedURL.urlToken) {
      await this.onToken(parsedURL.urlToken);
      return;
    }

    if (parsedURL.code) {
      await this.onCode(parsedURL.code, parsedURL.state);
    } else {
      this.redirectSSO(parsedURL);
    }
  }

  private parseURL() {
    const url = new URL(window.location.href);
    const params = url.pathname.split('/');
    let urlParams: any = {};
    urlParams.nav = params[1];
    urlParams.cName = params[2];
    urlParams.cId = params[3];
    urlParams.urlToken = params[4];
    urlParams.state = url.searchParams.get('state');
    urlParams.error = url.searchParams.get('error');
    urlParams.code = url.searchParams.get('code');
    urlParams.sector = url.searchParams.get('sector');
    return urlParams;
  }

  private getConfig() {
    const url = environment.config_url;
    const httpOptions = {
      headers: new HttpHeaders({
        'x-amz-security-token': this.auth.getToken()
      })
    }
    const body = { token: this.auth.getToken() };
    return this.http.post(url, null, httpOptions).toPromise();
  }

  private mergeConfig(config) {
    if(!config) return;
    if(config.header){
      this.updateHeader(config.header);
    }
    if(config.footer){
      this.updateFooter(config.footer);
    }
    if(config.pdf) {
      this.updatePdf(config.pdf);
    }
    if(config.sections){
     this.updateSections(config.sections);
    }
    return;
  }
  
  private updateHeader(header){
    if(header.logo && header.logo.image ){
      const token = this.auth.getToken();
      const url = `${environment.file_url}?access_token=${token}&asset_path=${header.logo.image}`;
      header.logo.image = url;
    }
    this.config.set('header', header);
  }

  private updateFooter(footer){
    if(footer.columns){
      const token = this.auth.getToken();
      for (let i = 0; i < footer.columns.length; i++) {
        const column = footer.columns[i];
        if(column.images){
          for (let j = 0; j < column.images.length; j++) {
            const image = column.images[j];
            if(image.url){
              const url = `${environment.file_url}?access_token=${token}&asset_path=${image.url}`;
              image.url = url;
            }
          }
        }
      }
    }
    this.config.set('footer', footer);
  }

  private updatePdf(pdf){
    const token = this.auth.getToken();
    if(pdf.logo_pdf && pdf.logo_pdf.url){
      const url = `${environment.file_url}?access_token=${token}&asset_path=${pdf.logo_pdf.url}`;
      pdf.logo_pdf.url = url;
    }
    if(pdf && pdf.pages){
      if(pdf.pages.page_first){
        const url = `${environment.file_url}?access_token=${token}&asset_path=${pdf.pages.page_first}`;
        pdf.pages.page_first = url;
      }
      if(pdf && pdf.pages.page_second){
        const url = `${environment.file_url}?access_token=${token}&asset_path=${pdf.pages.page_second}`;
        pdf.pages.page_second = url;
      }
      if(pdf && pdf.pages.page_last){
        const url = `${environment.file_url}?access_token=${token}&asset_path=${pdf.pages.page_last}`;
        pdf.pages.page_last = url;
      }
    }
    this.config.set('pdf', pdf);
  }

  private updateSections(sections){
    this.config.set('sections',sections);
  }

  private redirectSSO(parsedURL) {
    if(parsedURL.nav && parsedURL.cName && parsedURL.cId){
      let url = environment.sso_url;
      url += `&state=/${parsedURL.nav}/${parsedURL.cName}/${parsedURL.cId}`;
      window.location.href = url;
      throw new Error('Stop Loading');
    }else{
      this.angularLocation.go('/error');
    }
  }

  private onError(error) {
    console.log(error);
    this.auth.setToken(null);
    this.auth.setRefreshToken(null);
  }

  private async onToken(token) {
    try {
      this.auth.setToken(token);
      const config = await this.getConfig();
      this.mergeConfig(config);
    } catch (error) {
      console.log(error)
    }
  }

  private async onCode(code, state) {
    try {
      const res: any = await this.auth.useCode(code).toPromise();
      this.auth.setToken(res.access_token);
      this.auth.setRefreshToken(res.refresh_token);
      this.mergeConfig(res.companyConfiguration);
      const newState = state+'/'+res.access_token;
      this.angularLocation.replaceState(newState);
    } catch (error) {
      this.angularLocation.go('/error');
    }
  }
}

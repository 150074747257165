export const mock: any = {
    "company_basic_info":{
       "sector_analysis":false,
       "company_logo":"https://s3-eu-west-1.amazonaws.com/static-mastergraph-spaziodati-eu/resources/logos/25be666c0b6cef0e4f82dd11ac2801033153ebb8.jpeg",
       "company_payoff":"",
       "sector_name":[
          {
             "id":"70",
             "value":""
          }
       ],
       "sector_main":"Attività delle holding impegnate nelle attività gestionali (holding operative)",
       "sector_main_characteristics":[
          
       ],
       "isotypes_info":[
          
       ],
       "legal_class":"Società Di Capitale",
       "division":"Holding",
       "general_data":[
          {
             "label":"Ragione Sociale",
             "value":"ITALIAN ENTERTAINMENT NETWORK SOCIETA’ PER AZIONI IN FORMA ABBREVIATA I.E.N. S.P.A."
          },
          {
             "label":"Codice Fiscale",
             "value":"06008721000"
          },
          {
             "label":"Data Chiusura Bilancio",
             "value":"31/12/2019"
          },
          {
             "label":"Settore prevalente",
             "value":"Ateco (2007) - 70.10.00: <br />Attività delle holding impegnate nelle attività gestionali (holding operative)"
          },
          {
             "label":"Partita IVA",
             "value":"06008721000"
          },
          {
             "label":"Capitale Sociale",
             "value":4545000
          },
          {
             "label":"Sul mercato da",
             "value":"9/3/2000"
          },
          {
             "label":"Natura giuridica",
             "value":[
                "Società Di Capitale"
             ]
          }
       ],
       "employees":{
          "last_value":4,
          "last_update":"1/6/2020",
          "values":[
             {
                "value":4,
                "year":2020
             },
             {
                "value":3,
                "year":2019
             },
             {
                "value":4,
                "year":2018
             },
             {
                "value":13,
                "year":2017
             },
             {
                "value":14,
                "year":2016
             }
          ]
       },
       "locations":{
          "number":3,
          "values":[
             {
                "label":"Sede principale",
                "address":"Piazza Venezia, 11, 00187, Roma (RM)"
             },
             {
                "label":"Sede Secondaria",
                "address":"Via Pietro Maroncelli, 13, 20154, Milano (MI)"
             },
             {
                "label":"Magazzino",
                "address":"Via Prenestina, 683, 00155, Roma (RM)"
             }
          ]
       },
       "people":{
          "number":23,
          "items":[
             {
                "name":"Luigi",
                "last_name":"Abete",
                "birthdate":"17/2/1947",
                "roles":[
                   {
                      "label":"amministratore delegato",
                      "date_from":"24/10/2016"
                   },
                   {
                      "label":"consigliere",
                      "date_from":"4/2/2013"
                   },
                   {
                      "label":"ceo",
                      "date_from":""
                   }
                ]
             },
             {
                "name":"Giuseppe",
                "last_name":"Costa",
                "birthdate":"18/12/1956",
                "roles":[
                   {
                      "label":"amministratore delegato",
                      "date_from":"27/6/2016"
                   },
                   {
                      "label":"consigliere",
                      "date_from":"26/8/2014"
                   },
                   {
                      "label":"ceo",
                      "date_from":""
                   }
                ]
             },
             {
                "name":"Sergio",
                "last_name":"Castellani",
                "birthdate":"20/10/1947",
                "roles":[
                   {
                      "label":"presidente consiglio amministrazione",
                      "date_from":"23/4/2010"
                   },
                   {
                      "label":"consigliere",
                      "date_from":"27/6/2016"
                   },
                   {
                      "label":"preposto",
                      "date_from":"27/7/2000"
                   },
                   {
                      "label":"preposto della sede secondaria",
                      "date_from":"2/7/2001"
                   },
                   {
                      "label":"presidente",
                      "date_from":""
                   }
                ]
             }
          ]
       },
       "participations":{
          "last_update":"21/6/2019",
          "number":16,
          "total_participation_value":10675578.120000001,
          "values":[
             {
                "label":"FILMMASTER EVENTS S.R.L. IN FORMA ABBREVIATA FILMMASTER S.R.L.",
                "percentage":100,
                "value":120000
             },
             {
                "label":"K 2006 - SOCIETA’ A RESPONSABILITA’ LIMITATA ED, IN FORMA ABBREVI ATA, K 2006 - S.R.L.",
                "percentage":98,
                "value":44100
             },
             {
                "label":"FORMATIORIGINALI - SOCIETA’ A RESPONSABILITA’ LIMITATA ED, IN FORMA ABBREVIATA, FORMATIORIGINALI - S.R.L.",
                "percentage":95,
                "value":47500
             },
             {
                "label":"FILMMASTER PRODUCTIONS S.P.A.",
                "percentage":91,
                "value":91000
             },
             {
                "label":"FILM MASTER TELEVISION S.R.L.",
                "percentage":80,
                "value":36000
             },
             {
                "label":"CLIP TELEVISION S.R.L.",
                "percentage":80,
                "value":16800
             }
          ]
       },
       "shareholders":{
          "last_update":"15/7/2020",
          "number":16,
          "total_participation_value":10675578.120000001,
          "values":[
             {
                "label":"ABETE VALORE CULTURA S.R.L.",
                "percentage":20.97,
                "value":953256
             },
             {
                "label":"NETWORK HOLDING SPA",
                "percentage":16.42,
                "value":746229
             },
             {
                "label":"ITALIAN ENTERTAINMENT NETWORK SOCIETA’ PER AZIONI IN FORMA ABBREVIATA I.E.N. S.P.A.",
                "percentage":11.95,
                "value":543232
             },
             {
                "label":"COSTA EDUTAINMENT S.P.A.",
                "percentage":10.13,
                "value":460328
             },
             {
                "label":"D’AMICO SOCIETA’ DI NAVIGAZIONE S.P.A.",
                "percentage":9.41,
                "value":427828
             },
             {
                "label":"Marco Balich",
                "percentage":4.7,
                "value":213450
             }
          ]
       }
    },
    "economical_data":{
       "revenues":{
          "values":[
             {
                "year":2019,
                "value":1804000
             },
             {
                "year":2018,
                "value":2129000
             },
             {
                "year":2017,
                "value":1927000
             },
             {
                "year":2016,
                "value":1827000
             },
             {
                "year":2015,
                "value":1539000
             }
          ],
          "last_value":1804000,
          "last_update":"31/12/2019"
       },
       "ebitda":{
          "values":[
             
          ]
       },
       "profit":{
          "values":[
             {
                "year":2019,
                "value":-4187000
             },
             {
                "year":2018,
                "value":-862000
             },
             {
                "year":2017,
                "value":-513000
             },
             {
                "year":2016,
                "value":-799000
             },
             {
                "year":2015,
                "value":-2254000
             }
          ],
          "last_value":-4187000,
          "last_update":"31/12/2019"
       },
       "patrimonio_netto":{
          "values":[
             {
                "year":2019,
                "value":9237000
             },
             {
                "year":2018,
                "value":13424000
             },
             {
                "year":2017,
                "value":8433000
             },
             {
                "year":2016,
                "value":12492000
             },
             {
                "year":2015,
                "value":14791000
             }
          ],
          "last_value":9237000,
          "last_update":"31/12/2019"
       },
       "cash_flow":{
          "values":[
             
          ]
       },
       "roe_percentage":{
          "values":[
             {
                "year":2019,
                "value":-0.37
             },
             {
                "year":2018,
                "value":-0.079
             },
             {
                "year":2017,
                "value":-0.049
             },
             {
                "year":2016,
                "value":-0.059
             },
             {
                "year":2015,
                "value":-0.142
             }
          ],
          "last_value":-0.37,
          "last_update":"31/12/2019"
       },
       "profit_percentage":{
          "values":[
             
          ]
       },
       "capitale_circolante_netto_operativo":{
          "values":[
             
          ]
       },
       "rapporto_indebitamento":{
          "values":[
             
          ]
       },
       "ebitda_percentage":{
          "values":[
             
          ]
       },
       "patrimonio_netto_totale_attivo":{
          "values":[
             {
                "year":2019,
                "value":0.1842976855546688
             },
             {
                "year":2018,
                "value":0.25202290434619357
             },
             {
                "year":2017,
                "value":0.2705225676065826
             },
             {
                "year":2016,
                "value":0.45174122156728025
             },
             {
                "year":2015,
                "value":0.5830344120777327
             }
          ],
          "last_value":0.1842976855546688,
          "last_update":"31/12/2019"
       },
       "margine_operativo_lordo":{
          "values":[
             
          ]
       },
       "ebitda_margin":{
          "values":[
             
          ]
       },
       "indice_copertura_debiti":{
          "values":[
             
          ]
       },
       "indice_rotazione_crediti":{
          "values":[
             
          ]
       },
       "circolante_operativo_ricavi":{
          "values":[
             
          ]
       },
       "roe":{
          "values":[
             {
                "year":2019,
                "value":-0.37
             },
             {
                "year":2018,
                "value":-0.079
             },
             {
                "year":2017,
                "value":-0.049
             },
             {
                "year":2016,
                "value":-0.059
             },
             {
                "year":2015,
                "value":-0.142
             }
          ],
          "last_value":-0.37,
          "last_update":"31/12/2019"
       },
       "posizione_finanziaria_netta":{
          "values":[
             {
                "year":2019,
                "value":17048000
             },
             {
                "year":2018,
                "value":17016000
             },
             {
                "year":2017,
                "value":12056000
             },
             {
                "year":2016,
                "value":5141000
             },
             {
                "year":2015,
                "value":3032000
             }
          ],
          "last_value":17048000,
          "last_update":"31/12/2019"
       },
       "posizione_finanziaria_netta_ebitda":{
          "values":[
             
          ]
       }
    },
    "market_analysis":{
       "economical_data":[
          {
             "id":"70",
             "value":{
                "circolante_operativo_ricavi":{
                   "values":[
                      {
                         "year":2018,
                         "value":0.532
                      },
                      {
                         "year":2017,
                         "value":0.527
                      },
                      {
                         "year":2016,
                         "value":0.559
                      },
                      {
                         "year":2015,
                         "value":0.749
                      },
                      {
                         "year":2014,
                         "value":0.761
                      }
                   ],
                   "last_update":2018,
                   "last_value":0.532
                },
                "ebitda":{
                   "values":[
                      {
                         "year":2018,
                         "value":167.1
                      },
                      {
                         "year":2017,
                         "value":148.8
                      },
                      {
                         "year":2016,
                         "value":127.5
                      },
                      {
                         "year":2015,
                         "value":124
                      },
                      {
                         "year":2014,
                         "value":100
                      }
                   ],
                   "last_update":2018,
                   "last_value":167.1
                },
                "ebitda_margin":{
                   "values":[
                      {
                         "year":2018,
                         "value":0.105
                      },
                      {
                         "year":2017,
                         "value":0.101
                      },
                      {
                         "year":2016,
                         "value":0.094
                      },
                      {
                         "year":2015,
                         "value":0.095
                      },
                      {
                         "year":2014,
                         "value":0.082
                      }
                   ],
                   "last_update":2018,
                   "last_value":0.105
                },
                "roe":{
                   "values":[
                      {
                         "year":2018,
                         "value":0.142
                      },
                      {
                         "year":2017,
                         "value":0.147
                      },
                      {
                         "year":2016,
                         "value":0.125
                      },
                      {
                         "year":2015,
                         "value":0.064
                      },
                      {
                         "year":2014,
                         "value":0.044
                      }
                   ],
                   "last_update":2018,
                   "last_value":0.142
                },
                "rapporto_indebitamento":{
                   "values":[
                      {
                         "year":2018,
                         "value":-0.416
                      },
                      {
                         "year":2017,
                         "value":-0.37
                      },
                      {
                         "year":2016,
                         "value":-0.304
                      },
                      {
                         "year":2015,
                         "value":-0.185
                      },
                      {
                         "year":2014,
                         "value":0.274
                      }
                   ],
                   "last_update":"",
                   "last_value":0
                },
                "indice_copertura_debiti":{
                   "values":[
                      {
                         "year":2018,
                         "value":96.48
                      },
                      {
                         "year":2017,
                         "value":97.2
                      },
                      {
                         "year":2016,
                         "value":96.84
                      },
                      {
                         "year":2015,
                         "value":117
                      },
                      {
                         "year":2014,
                         "value":143.64
                      }
                   ],
                   "last_update":2018,
                   "last_value":96.48
                },
                "indice_rotazione_crediti":{
                   "values":[
                      {
                         "year":2018,
                         "value":113.4
                      },
                      {
                         "year":2017,
                         "value":113.04
                      },
                      {
                         "year":2016,
                         "value":116.28
                      },
                      {
                         "year":2015,
                         "value":123.12
                      },
                      {
                         "year":2014,
                         "value":150.84
                      }
                   ],
                   "last_update":2018,
                   "last_value":113.4
                },
                "revenues":{
                   "values":[
                      {
                         "year":2018,
                         "value":130.548
                      },
                      {
                         "year":2017,
                         "value":120.953
                      },
                      {
                         "year":2016,
                         "value":111.957
                      },
                      {
                         "year":2015,
                         "value":107.444
                      },
                      {
                         "year":2014,
                         "value":100
                      }
                   ],
                   "last_update":0,
                   "last_value":0
                }
             },
             "infoSector":false,
             "economicsSector":true
          }
       ],
       "critical_variables":[
          
       ]
    },
    "menace_opportunities":[
       
    ]
 }